@import "../../../assets/style/variables.module.scss";

.dropDown {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 7px 15px;
    border: 1px solid $--stroke-blue;
    border-radius: 5px;
    background: white;
    position: relative;
    z-index: 1;

    svg {
      transform: rotate(-90deg);
      transition: all 0.5s ease;
    }
  }
}

.selectionWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: scroll;
  box-sizing: border-box;
  border: 1px solid $--stroke-blue;
  position: relative;
  top: -5px;
  height: 0px;
  transition: all 0.5s ease;
  padding: 0;

  button {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    color: #686868;
    font-weight: 400;
    letter-spacing: 0.04em;
    text-align: left;

    .square {
      width: 17px;
      height: 17px;
      border: 1px solid $--stroke-blue;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .active {
    color: #454545;
    font-weight: 600;
    .square {
      background: $--main-blue;
    }
  }
}

.open {
  height: 230px;
  padding: 20px 15px 15px 15px;
}

.rotate {
  svg {
    transform: rotate(0deg) !important;
  }
}
