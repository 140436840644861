@import "../../../assets/style/variables.module.scss";

.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 100px;
  gap: 136px;

  .info {
    max-width: 390px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    h1 {
      font-size: 62px;
      color: $--text-color;
      width: 100%;
      font-weight: 600;
    }
    h2 {
      font-size: 77px;
      color: $--main-blue;
      font-family: $--cormorant;
    }
    p {
      font-size: 16px;
      color: $--text-color;
      margin-top: 24px;
      line-height: 150.8%;
      width: 100%;
    }

    .mainButton {
      background: $--main-blue;
      color: white;
      font-weight: 700;
      padding: 14px;
      width: 250px;
      text-align: center;
      box-shadow: 0px 0px 18px 1px rgba(39, 43, 135, 0.25);
      border-radius: 8px;
    }

    .buttonWrapper {
      display: flex;
      flex-direction: column;
      margin-top: 35px;
      align-items: center;
      gap: 18px;

      .contact {
        color: $--main-blue;
        font-weight: 600;
        text-decoration: underline;
      }
    }
  }

  .videoWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    .videoCard {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s ease;
      video {
        transition: all 0.3s ease;
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 10;
        cursor: pointer;
      }
      img {
        border: 1px solid #ffffff;
        box-shadow: 0px 4px 161px 13px rgba(40, 44, 136, 0.3);
        border-radius: 20px;
        transition: all 0.3s ease;
        height: 350px;
        min-width: 600px;
        object-fit: cover;
        object-position: center;
      }
      button {
        position: absolute;
        z-index: 1;
      }
    }
  }
}

.active {
  gap: 0;
  align-items: flex-start;
  .info {
    width: 0;
    opacity: 0;
  }
  .videoWrapper {
    width: 100%;
    height: 100%;

    .videoCard {
      align-items: center;
      justify-content: center;
      width: 100%;
      position: static;
    }

    video {
      width: 100% !important;
      height: 100% !important;
      box-shadow: 0px 4px 161px 13px rgba(40, 44, 136, 0.3);
      border-radius: 30px;
      border: 1px solid #dfe6ff;
      opacity: 1 !important;
      position: static !important;
    }

    img {
      opacity: 0;
      width: 0;
    }
    button {
      opacity: 0;
    }
  }
}

@media only screen and (max-width: 940px) {
  .head {
    flex-direction: column-reverse;
    width: 100%;
    gap: 40px;
    margin-top: 50px;

    .info {
      width: 100%;
      align-items: center;
      justify-content: center;
      h1 {
        font-size: 34px;
        text-align: center;
      }

      h2 {
        text-align: center;
      }

      p {
        font-size: 14px;
        max-width: 300px;
        text-align: center;
      }
    }

    .videoWrapper {
      width: 100%;

      .videoCard {
        width: 100%;

        img {
          width: 100%;
          height: auto;
          min-width: unset;
        }
      }
    }
  }
}
