@import "../../assets/style//variables.module.scss";

.messageContainer {
  background: #fafafa;
  min-height: 100vh;
  display: flex;
  width: 100%;

  .messagePart {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 128px 57px 0 45px;

    h3 {
      font-size: 25px;
      color: $--text-color;
      letter-spacing: 0.05em;
      font-weight: 600;
      margin-bottom: 25px;
    }
  }
}

.messageWrapper {
  width: 72vw;
  display: flex;
  height: 80vh;
  box-sizing: border-box;

  .userWrapper {
    width: 30%;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;

    .newMessage {
      position: absolute;
      bottom: 0;
      z-index: 1;
      height: 60px;
      background: linear-gradient(92.08deg, #272b87 -11.22%, #3990d9 145.9%);
      width: 100%;
      color: white;
      letter-spacing: 0.09em;
    }

    .messageCardWrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      height: 100%;
      overflow: scroll;
      border-bottom: 1px solid $--stroke-blue;
      border-top: 1px solid $--stroke-blue;
      border-radius: 0 0 10px 10px;
      background: white;
      padding-bottom: 60px;
      padding-right: 0;

      .active {
        background: linear-gradient(92.08deg, #272b87 -11.22%, #3990d9 145.9%);

        .reciverPhoto {
          background: $--stroke-blue !important;
          color: $--main-blue !important;
        }

        .info {
          h5,
          h6 {
            color: white;
          }
        }
      }

      .messageCard {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 20px;
        height: 60px;
        min-height: 60px;
        border-left: 1px solid $--stroke-blue;
        border-right: 1px solid $--stroke-blue;
        padding: 0 0 0 25px;
        box-sizing: border-box;
        padding-right: 10px;

        &:last-child {
          //   border-radius: 0 0 10px 10px;
        }

        &:not(:first-child) {
          border-top: 1px solid $--stroke-blue;
        }

        // img {
        //   width: 35px;
        //   height: 35px;
        //   border-radius: 50%;
        //   border: 1px solid $--stroke-blue;
        // }

        .reciverPhoto {
          width: 35px;
          height: 35px;
          min-width: 35px;
          min-height: 35px;
          border-radius: 50%;
          border: 1px solid $--stroke-blue;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          background: $--main-blue;
        }

        .info {
          display: flex;
          flex-direction: column;
          gap: 4px;
          align-items: flex-start;

          h5 {
            font-size: 12px;
            font-weight: 600;
          }
          h6 {
            font-size: 10px;
            font-weight: 400;
            text-align: left;
          }
        }
      }
    }
  }

  .messageBox {
    border: 1px solid $--stroke-blue;
    width: 70%;
    height: 100%;
    border-radius: 5px;
    position: relative;

    .messageArea {
      background: #f5f5f5;
      width: 100%;
      height: 100%;
      border-radius: 5px;
      overflow: scroll;
      padding-bottom: 80px;
      box-sizing: border-box;
      padding-top: 30px;
      padding-left: 35px;
      padding-right: 35px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      position: relative;

      .emptyElement {
        height: 50px;
        min-height: 50px;
        width: 100%;
      }

      .loadingWrapper {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        overflow: hidden;
        z-index: 10;

        .inner {
          position: static;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          z-index: 1;
        }
      }

      .dateCard {
        background: white;
        border-radius: 5px;
        align-self: center;
        padding: 5px 10px;
        border: 1px solid $--stroke-blue;
        font-size: 12px;
        color: $--main-blue;
      }

      .message {
        display: flex;
        gap: 10px;
        width: 70%;

        .profilePhoto {
          width: 30px;
          height: 30px;
          min-width: 30px;
          min-height: 30px;
          border-radius: 50%;
          border: 1px solid $--stroke-blue;
          display: flex;
          align-items: center;
          justify-content: center;
          background: $--stroke-blue;
          font-size: 12px;
        }

        .profile {
          width: 20px;
          height: 20px;
          min-width: 20px;
          min-height: 20px;
          border-radius: 50%;
          border: 1px solid $--stroke-blue;
        }

        .card {
          p {
            box-shadow: 0px 0px 15px rgba(136, 136, 136, 0.25);
            border-radius: 5px;
            background: white;
            letter-spacing: 0.05em;
            color: $--text-color;
            padding: 15px 25px 15px 15px;
            box-sizing: border-box;
            line-height: 145%;
            font-size: 12px;
          }

          .contentImage {
            height: 100px;
            box-shadow: 0px 0px 15px rgba(136, 136, 136, 0.25);
            border-radius: 5px;
            padding: 15px 15px 15px 15px;
            background: white;
          }

          .contentFile {
            height: 30px;
            box-shadow: 0px 0px 15px rgba(136, 136, 136, 0.25);
            border-radius: 5px;
            padding: 15px 15px 15px 15px;
            background: white;
            color: $--main-blue;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 20px;

            svg {
              height: 50px;

              path {
                fill: $--main-blue;
              }
            }

            span {
              display: flex;
              align-items: center;

              svg {
                height: 20px;
              }
            }
          }

          .videoWrapper {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            .contentVideo {
              height: 100px;
              box-shadow: 0px 0px 15px rgba(136, 136, 136, 0.25);
              border-radius: 5px;
              padding: 15px 15px 15px 15px;
              background: white;
            }

            svg {
              position: absolute;
            }
          }

          .meet {
            box-shadow: 0px 0px 15px rgba(136, 136, 136, 0.25);
            border-radius: 5px;
            background: white;
            letter-spacing: 0.05em;
            color: $--text-color;
            padding: 15px 25px 15px 15px;
            box-sizing: border-box;
            line-height: 145%;
            font-size: 12px;
            display: flex;
            justify-content: flex-start;
            text-align: left;
            gap: 20px;

            svg {
              height: 20px;

              path {
                fill: $--main-blue;
              }
            }
          }

          h6 {
            font-size: 10px;
            color: #8a8a8a;
            font-weight: 300;
            margin-top: 10px;
          }
        }
      }

      .sender {
        flex-direction: row-reverse;
        position: relative;
        right: -30%;

        .profilePhoto {
          background: $--main-blue;
          color: white;
          border: 1px solid white;
        }

        .card {
          p {
            background: linear-gradient(
              92.08deg,
              #272b87 -11.22%,
              #3990d9 145.9%
            );
            color: white;
          }

          .meet {
            background: linear-gradient(
              92.08deg,
              #272b87 -11.22%,
              #3990d9 145.9%
            );
            color: white;
            svg {
              path {
                fill: white;
              }
            }
          }

          .contentImage {
            background: linear-gradient(
              92.08deg,
              #272b87 -11.22%,
              #3990d9 145.9%
            );
          }

          .contentFile {
            background: linear-gradient(
              92.08deg,
              #272b87 -11.22%,
              #3990d9 145.9%
            );

            color: white;

            svg {
              path {
                fill: white;
              }
            }
          }

          .videoWrapper {
            .contentVideo {
              background: linear-gradient(
                92.08deg,
                #272b87 -11.22%,
                #3990d9 145.9%
              );
            }
          }
        }
      }
    }

    .bottomBar {
      width: 100%;
      background: white;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 60px;
      position: absolute;
      bottom: 0;
      padding: 11px 20px;
      box-sizing: border-box;
      box-shadow: 0px -10px 20px rgba(196, 196, 196, 0.25);
      border-top: 1px solid $--stroke-blue;
      border-radius: 0 0 5px 5px;

      .popup {
        position: absolute;
        background: red;
        bottom: 65px;
        left: 20px;
        z-index: 1;
        display: flex;
        box-shadow: 0px -10px 20px rgba(196, 196, 196, 0.25);
        flex-direction: column;
        background: white;
        padding: 20px 20px;
        border-radius: 5px;
        border: 1px solid $--stroke-blue;
        gap: 10px;

        button {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 7px;
          border: 1px solid $--stroke-blue;
          padding: 10px 10px 9px 10px;
          border-radius: 5px;
          background: $--stroke-blue;
          color: $--main-blue;
          font-weight: 500;
          position: relative;

          input {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            opacity: 0;
            cursor: pointer;
          }

          svg {
            height: 20px;
            width: auto;
            margin-bottom: 2px;

            path {
              fill: $--main-blue;
            }
          }
        }
      }

      .inputWrapper {
        margin-right: 10px;
        width: 760px;
        height: 100%;
        position: relative;

        .fileWrapper {
          position: absolute;
          width: 100%;
          height: 100%;
          background: $--stroke-blue;
          border-radius: 5px;
          display: flex;
          align-items: center;
          padding-left: 5px;
          box-sizing: border-box;
          gap: 10px;

          .iconWrapper {
            margin-bottom: -3px;
            svg {
              width: 25px;
              height: 25px;

              path {
                fill: $--main-blue;
              }
            }
          }

          img {
            width: 25px;
            height: 25px;
          }

          h5 {
            font-size: 14px;
            font-weight: 500;
            color: $--main-blue;
          }

          button {
            background: #ff6767;
            border-radius: 3px;
            width: 30px;
            height: 30px;
            position: absolute;
            right: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5px;
            svg {
              width: 100%;
              height: 100%;
            }
          }
        }

        input {
          border: 1px solid $--stroke-blue;
          box-shadow: 0px 0px 10px rgba(166, 166, 166, 0.25);
          height: 100%;
          border-radius: 5px;
          width: 100%;
          padding-left: 10px;

          box-sizing: border-box;
        }
      }

      .attachment {
        height: 100%;
        width: 38px;
        min-width: 38px;
        border: 1px solid $--stroke-blue;
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        padding: 5px;
      }

      .translate {
        height: 100%;
        width: 38px;
        min-width: 38px;
        border: 1px solid $--stroke-blue;
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        padding: 5px;
      }

      .activeTranslate {
        background: $--main-blue;
        svg {
          path {
            fill: white;
          }
        }
      }

      .send {
        height: 100%;
        width: 50px;
        max-width: 50px;
        min-width: 50px;
        background: linear-gradient(92.08deg, #272b87 -11.22%, #3990d9 145.9%);
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.iframeWrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba($color: $--main-blue, $alpha: 0.6);
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;

  .close {
    position: absolute;
    top: 50px;
    right: 50px;
    background: white;
    border: 1px solid $--stroke-blue;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    color: #ff6767;
  }

  iframe {
    width: 80%;
    height: 80%;
  }
}

.contentPopup {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba($color: $--main-blue, $alpha: 0.6);
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;

  .close {
    position: absolute;
    top: 50px;
    right: 50px;
    background: white;
    border: 1px solid $--stroke-blue;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    color: #ff6767;
  }

  img {
    height: 60%;
  }

  video {
    width: 80%;
  }
}

.ldsRipple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  scale: 0.2;
  transform: translateX(-10px);
}
.ldsRipple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: ldsRipple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.ldsRipple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes ldsRipple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

@media only screen and (max-width: 940px) {
  .messageContainer {
    .messagePart {
      padding: 0;
      padding-top: 150px;
      padding-right: 30px;
      padding-left: 30px;
      box-sizing: border-box;
      width: 100%;

      .messageWrapper {
        width: 100%;
        flex-direction: column;
        .userWrapper {
          width: 100%;
          height: 20vh;
        }

        .messageBox {
          width: 100%;
          position: static;
          height: 45vh;

          .messageArea {
            padding-left: 20px;
            .message {
              width: 100%;
              right: -20px;
            }
          }

          .bottomBar {
            position: fixed;
            left: 0;
            bottom: 0;
            padding: 10px 20px;

            input {
              width: 200px;
            }
          }
        }
      }
    }
  }
}
