@import "../../../assets/style/variables.module.scss";

.filterArea {
  width: 20vw;
  min-width: 20vw;
  height: 100vh;
  border-right: 1px solid $--stroke-blue;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 30px 40px 45px 45px;
  box-sizing: border-box;
  overflow: scroll;
  padding-top: 130px;

  .resetFilter {
    border: 1px solid $--stroke-blue;
    padding: 12px 0;
    width: 100%;
    border-radius: 5px;
    color: $--main-blue;
    font-weight: 500;
  }

  .wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 10px;
  }

  .filterTop {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .title {
      width: 100%;
      display: flex;
      justify-content: space-between;

      h5,
      button {
        font-size: 15px;
        color: #959a9f;
        font-weight: 500;
        letter-spacing: 0.04em;
      }
    }

    .buttonWrapper {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;

      button {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 6px 10px;
        border: 1px solid $--stroke-blue;
        border-radius: 5px;
      }
    }
  }
}

.shadow {
  display: none;
}

@media only screen and (max-width: 940px) {
  .filterArea {
    position: fixed;
    z-index: 100;
    width: 70%;
    background: white;
    left: 0;
    padding-top: 50px;
  }

  .shadowBack {
    position: fixed;
    width: 100vw;
    min-width: 100vh;
    height: 100vh;
    background: rgba($color: #000000, $alpha: 0.6);
    top: 0;
    left: 0;
    z-index: 99;
  }
}
