@import "../../../assets/style/variables.module.scss";

.calendar {
  width: 50%;
}

.headPart {
  background: linear-gradient(92.08deg, #272b87 -11.22%, #3990d9 145.9%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 42px;
  padding: 0 20px;
  box-sizing: border-box;
  border-radius: 10px 10px 0 0;

  p {
    font-size: 14px;
    color: white;
    font-weight: 700;
  }

  .right {
    svg {
      transform: rotate(180deg);
    }
  }
}

.weekWrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  height: 43px;
  box-sizing: border-box;
  border-left: 1px solid $--stroke-blue;
  border-right: 1px solid $--stroke-blue;

  .weekRow {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #959a9f;
    height: 100%;

    &:not(:last-child) {
      border-right: 1px solid #e9e9e9;
    }
  }
}

.dayWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid $--stroke-blue;
  box-sizing: border-box;

  //   grid-template-rows:

  .dayWeek {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    border-bottom: 1px solid #e9e9e9;
  }

  .dayCell {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 63px;
    position: relative;

    &:not(:first-child) {
      border-left: 1px solid #e9e9e9;
    }
  }

  .nextMonth {
    color: #e0e0e0;
  }

  .today {
    color: white;

    span {
      background: $--main-blue;
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
    }
  }

  .default {
    color: #959a9f;
  }

  .orangeCircle {
    width: 10px;
    height: 10px;
    background: $--secondary-orange;
    position: absolute;
    right: 10px;
    top: 10px;
    border-radius: 50%;
  }
  .blueCircle {
    width: 10px;
    height: 10px;
    background: $--main-blue;
    position: absolute;
    right: 10px;
    top: 10px;
    border-radius: 50%;
  }
  .secondaryBlueCircle {
    width: 10px;
    height: 10px;
    background: $--secondary-blue;
    position: absolute;
    right: 10px;
    top: 10px;
    border-radius: 50%;
  }
}

@media only screen and (max-width: 940px) {
  .calendar {
    width: 100%;
  }
}
