@import "../../../assets/style/variables.module.scss";

.feedback {
  background: white;
  border: 1px solid $--stroke-blue;
  width: 45%;
  height: 355px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 40px;
  box-sizing: border-box;
  border-radius: 5px;

  .success {
    color: green;
    font-size: 12px;
  }

  .title {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
    h2 {
      color: $--text-color;
      font-size: 14px;
      font-weight: 600;
    }
    h3 {
      color: #959a9f;
      font-size: 11px;
      font-weight: 400;
    }
  }

  .content {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
  }

  .feedbackButton {
    border-radius: 5px;
    background: linear-gradient(92.08deg, #272b87 -11.22%, #3990d9 145.9%);
    padding: 9px 16px;
    color: white;
    font-weight: 500;
    font-size: 11px;
    letter-spacing: 0.04em;
    margin-top: 10px;
  }

  label {
    width: 100%;
    margin-top: 0;
    margin-bottom: 20px;

    h5 {
      color: $--text-color;
      letter-spacing: 0.05em;
      font-weight: 500;
      font-size: 12px;
      text-align: center;
    }

    textarea {
      width: 100%;
      background: #fafafa;
      border: 1px solid $--stroke-blue;
      resize: none;
      height: 115px;
      margin-top: 10px;
      border-radius: 3px;
      padding-left: 12px;
      box-sizing: border-box;
      padding-top: 13px;
      font-size: 14px;
    }
  }

  .buttonWrapper {
    display: flex;
    align-items: center;
    gap: 55px;
    width: 200px;
  }
}

@media only screen and (max-width: 940px) {
  .feedback {
    width: 100%;
  }
}
