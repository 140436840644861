@import "../../../assets/style/variables.module.scss";

.doctorCard {
  width: 100%;
  display: flex;
  flex-direction: column;

  .imageCard {
    width: 100%;
    height: 400px;
    position: relative;
    overflow: hidden;
    background: $--main-blue;
    border-radius: 10px 10px 0 0;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: linear-gradient(
        358.69deg,
        #000000 -6.81%,
        rgba(0, 0, 0, 0) 36.16%
      );
      z-index: 1;
    }
  }

  .card {
    background: white;
    border: 1px solid $--stroke-blue;
    border-radius: 0 0 10px 10px;
    padding: 35px 15px 17px 15px;
    box-sizing: border-box;
    position: relative;
    z-index: 1;

    h3 {
      font-size: 20px;
      color: $--text-color;
      font-weight: 700;
    }

    .subTitle {
      font-size: 14px;
      font-weight: 400;
      color: $--text-color;
      margin-top: 7px;
    }

    h6 {
      font-size: 14px;
      color: $--text-color;
      font-weight: 500;
      display: flex;
      align-items: center;
      gap: 7px;
      margin-top: 13.5px;
      margin-bottom: 15px;

      svg {
        height: 20px;
        width: 20px;

        border-radius: 50%;
        border: 1px solid $--stroke-blue;
        padding: 5px;
      }
    }

    .operationCard {
      display: flex;
      align-items: center;
      position: absolute;
      top: -25px;
      gap: 5px;

      span {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $--stroke-blue;
        left: 0;
        background: white;
        z-index: 1;
        padding: 10px;
        box-sizing: border-box;

        img {
          width: 100%;
          max-height: 40px;
          object-fit: contain;
          object-position: center;
        }
      }
      h5 {
        border: 1px solid $--stroke-blue;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        padding: 6px 7px 6px 25px;
        position: relative;
        left: 30px;
        font-size: 16px;
        color: $--text-color;
        font-weight: 600;
        background: white;
      }
    }

    .starWrapper {
      margin-top: 5px;
      display: flex;
      gap: 7px;
    }

    .bottom {
      width: 100%;
      border-top: 1px solid $--stroke-blue;
      padding-top: 18px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        color: #0eb250;
        font-size: 24px;
        font-weight: 600;
      }
      button {
        background: linear-gradient(92.08deg, #272b87 -11.22%, #3990d9 145.9%);
        color: white;
        font-size: 11px;
        padding: 7.5px 16px;
        border-radius: 3px;
        letter-spacing: 0.05em;
      }
    }
  }
}

.list {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  .card {
    display: flex;
    flex-direction: row;
    width: calc(100% - 220px);
    justify-content: space-between;
    align-items: center;
    padding: 65px 40px 25px 40px;
    border-radius: 0 5px 5px 0;
    border: unset;
    border-right: 1px solid $--stroke-blue;
    border-top: 1px solid $--stroke-blue;
    border-bottom: 1px solid $--stroke-blue;
    position: relative;
    &::before {
      content: "";
      width: 5px;
      height: 1px;
      background: $--stroke-blue;
      position: absolute;
      bottom: -1px;
      left: -5px;
    }
    &::after {
      content: "";
      width: 5px;
      height: 1px;
      background: $--stroke-blue;
      position: absolute;
      top: -1px;
      left: -5px;
    }

    .operationCard {
      left: -23.5px;
      top: 8px;
    }

    h6 {
      margin-bottom: 7px;
    }

    .city {
      font-size: 11px;
      color: $--text-color;
      font-weight: 500;
      margin-bottom: 12px;
    }

    .hospital {
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 14px;
      color: $--text-color;
    }

    .right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      gap: 35px;
      .resume {
        display: flex;
        position: relative;

        .resumeTitle {
          position: absolute;
          left: -15px;
          top: -15px;
          background: white;
          border: 1px solid $--stroke-blue;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;
          color: #686868;
          font-size: 12px;
          font-weight: 700;
          padding: 3px 10px;
          border-radius: 5px;
        }

        p {
          color: #686868;
          border: 1px solid $--stroke-blue;
          padding: 18px 13px 8px 13px;
          border-radius: 5px;
          max-width: 240px;
        }
      }

      .location {
        display: flex;
        gap: 30px;

        .column {
          display: flex;
          align-items: center;
          gap: 10px;
          div {
            display: flex;
            flex-direction: column;
            gap: 5px;

            span {
              font-size: 12px;
              color: $--text-color;
              font-weight: 500;

              &:first-child {
                font-weight: 700;
              }
            }
          }
        }
      }
    }

    .bottom {
      border-top: unset;
      flex-direction: column;
      width: 120px;
      display: flex;
      padding: unset;

      span {
        position: relative;
        top: -10px;
        font-size: 32px;
      }

      button {
        height: 30px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        &:nth-child(2) {
          margin-top: 25px;
          background: white !important;
          border: 1px solid $--main-blue !important;
          color: $--main-blue;
        }

        &:last-child {
          margin-top: 10px;
        }
      }
    }
  }

  .imageCard {
    border-radius: 5px 10px 10px 5px;
    width: 220px;
    height: 230px;

    &::before {
      display: none;
    }
  }
}

@media only screen and (max-width: 940px) {
  .list {
    flex-direction: column;

    .imageCard {
      width: 100%;

      img {
        height: 100%;
      }
    }

    .card {
      width: 100%;
      padding: 0;
      flex-direction: column;
      margin-top: -4px;
      border-radius: 0;
      border-left: 1px solid #dfe6ff;
      box-sizing: border-box;
      padding: 20px;
      padding-top: 40px;
      .left,
      .right,
      .bottom {
        width: 100%;
      }

      .left {
        margin-bottom: 40px;
        .operationCard {
          left: 15.5px;
          top: -16px;
        }
      }

      .bottom {
        margin-top: 20px;
        span {
          position: static;
        }
        button {
          &:nth-child(2) {
            margin-top: 10px;
          }
        }
      }
    }
  }
}
