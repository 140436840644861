@import "../../assets/style/variables.module.scss";

.blogDetail {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;

  .wrapper {
    width: 100%;
    max-width: 1200px;

    .content {
      width: 100%;
      padding-top: 30px;

      .head {
        width: 100%;
        height: 550px;
        position: relative;
        border-radius: 20px;
        overflow: hidden;

        &::before {
          content: "";
          width: 100%;
          height: 300px;
          position: absolute;
          bottom: 0;
          background: linear-gradient(
            180deg,
            rgba(39, 43, 135, 0) 0%,
            rgba(39, 43, 135, 1) 70%
          );
          z-index: 2;
        }

        img {
          width: 100%;
          height: 100%;
          position: absolute;
          object-fit: cover;
          object-position: center;
          z-index: 1;
        }

        .info {
          position: absolute;
          left: 50px;
          bottom: 50px;
          z-index: 3;
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          width: calc(100% - 100px);
          box-sizing: border-box;

          h1 {
            font-size: 62px;
            color: white;
            max-width: 600px;
            font-weight: 500;
            line-height: 75px;
          }

          .person {
            display: flex;
            flex-direction: column;
            gap: 5px;

            h5 {
              font-size: 18px;
              color: white;
              font-weight: 600;
            }
            h6 {
              font-size: 14px;
              color: #cbcbcb;
              font-weight: 400;
            }
          }
        }
      }

      .text {
        padding: 0 120px;
        display: flex;
        flex-direction: column;
        gap: 25px;
        margin-top: 25px;

        h1 {
          font-size: 28px;
          font-weight: 700;
        }

        h2 {
          font-size: 26px;
          font-weight: 600;
        }

        h3 {
          font-size: 24px;
          font-weight: 500;
        }

        p {
          font-size: 18px;
          line-height: 24px;
        }
      }
    }
  }
}

@media only screen and (max-width: 940px) {
  .blogDetail {
    .wrapper {
      max-width: unset;
      width: 90%;

      .content {
        .head {
          height: 300px;
          &::before {
            content: "";
            height: 250px;
          }
          .info {
            left: 25px;
            bottom: 25px;
            width: calc(100% - 50px);
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;
            h1 {
              font-size: 24px;
              line-height: 32px;
            }
          }
        }

        .text {
          padding: 0 20px;
        }
      }
    }
  }
}
