@import "../../assets/style/variables.module.scss";

.operationsContainer {
  display: flex;
  box-sizing: border-box;
  background: #fefefe;
}

.empty {
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 24px;
  font-weight: 400;
}

.operationCard {
  width: 100%;
  border-radius: 5px;
  box-sizing: border-box;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  padding: 0 45px;
  padding-top: 128px;

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .main {
      height: 45px;
      background: $--main-blue;
      border-radius: 5px;
      color: white;
      padding: 0 20px;
    }
  }

  .title {
    font-size: 25px;
    color: $--text-color;
    letter-spacing: 0.05em;
    font-weight: 600;
  }

  .cardWrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    box-sizing: border-box;

    .card {
      background: white;
      display: flex;
      box-sizing: border-box;
      padding: 17px 54px 19px 35px;
      border-radius: 5px;
      align-items: center;
      justify-content: space-between;
      box-shadow: 0px 0px 22px rgba(194, 194, 194, 0.25);

      .left {
        display: flex;
        flex-direction: column;
        gap: 15px;
        .doctor {
          display: flex;
          align-items: center;
          gap: 15px;

          img {
            width: 35px;
            height: 35px;
            border: 1px solid $--stroke-blue;
            border-radius: 50%;
          }

          h5 {
            font-size: 12px;
            color: $--text-color;
            font-weight: 600;
          }
        }
        .operation {
          display: flex;
          align-items: center;
          gap: 15px;

          .icon {
            width: 35px;
            height: 35px;
            border: 1px solid $--stroke-blue;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          h5 {
            font-size: 12px;
            color: $--text-color;
            font-weight: 600;
          }
        }
        .flight {
          display: flex;
          align-items: center;
          gap: 15px;

          .icon {
            width: 35px;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .info {
            display: flex;
            flex-direction: column;
            gap: 4px;
            h6 {
              font-size: 12px;
              font-weight: 700;
              color: $--text-color;
            }

            p {
              font-size: 12px;
              color: $--text-color;
              font-weight: 400;
            }
          }
        }
      }

      .right {
        display: flex;
        border: 1px solid $--stroke-blue;
        padding: 20px 35px;
        box-sizing: border-box;
        gap: 18px;
        border-radius: 10px;

        .active {
          svg {
            path {
              filter: drop-shadow(0px 0px 2px rgba(255, 221, 103, 0.75));
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 940px) {
  .operationCard {
    width: 100%;
    padding: 0 20px;
    padding-top: 140px;

    .cardWrapper {
      .card {
        width: 100%;
        display: flex;
        flex-direction: column;
        background: white;
        border-radius: 5px;

        .topCenter {
          display: flex;
          padding: 4px 25px;
          box-sizing: border-box;
          flex-wrap: wrap;

          .doctor {
            display: flex;
            align-items: center;
            gap: 15px;
            height: 55px;
            box-sizing: border-box;

            .avatar {
              width: 35px;
              height: 35px;
              border: 1px solid $--stroke-blue;
              object-fit: cover;
              object-position: center;
              border-radius: 50%;
              align-items: center;
              display: flex;
              justify-content: center;
              font-size: 12px;
              color: white;
              background: $--main-blue;
              padding-top: 3px;
              box-sizing: border-box;
            }

            h5 {
              font-size: 12px;
              color: $--text-color;
              font-weight: 600;
            }

            img {
              width: 35px;
              height: 35px;
              border: 1px solid $--stroke-blue;
              object-fit: cover;
              object-position: center;
              border-radius: 50%;
            }
          }

          .operation {
            display: flex;
            gap: 15px;
            align-items: center;
            height: 55px;
            box-sizing: border-box;
            margin-left: 20px;

            h5 {
              font-size: 12px;
              color: $--text-color;
              font-weight: 600;
            }

            .icon {
              width: 35px;
              height: 35px;
              border: 1px solid $--stroke-blue;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              background: white;
              padding: 5px;
              box-sizing: border-box;

              svg {
                path {
                  fill: $--main-blue;
                }
              }
            }
          }
        }

        .center {
          display: flex;
          padding: 4px 25px;
          box-sizing: border-box;
          gap: 10px;
          border-top: 1px solid #f2f2f2;
          border-bottom: 1px solid #f2f2f2;

          .flight {
            display: flex;
            gap: 15px;
            align-items: center;
            height: 55px;
            box-sizing: border-box;

            .icon {
              width: 35px;
              height: 35px;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .info {
              display: flex;
              flex-direction: column;
              gap: 4px;
              h6 {
                font-size: 12px;
                font-weight: 700;
                color: $--text-color;
              }

              p {
                font-size: 12px;
                color: $--text-color;
                font-weight: 400;
              }
            }
          }

          .location {
            display: flex;
            gap: 15px;
            align-items: center;
            height: 55px;
            box-sizing: border-box;

            .icon {
              width: 35px;
              height: 35px;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .info {
              display: flex;
              flex-direction: column;
              gap: 4px;
              h6 {
                font-size: 12px;
                font-weight: 700;
                color: $--text-color;
              }

              p {
                font-size: 12px;
                color: $--text-color;
                font-weight: 400;
              }
            }
          }
        }

        .top {
          display: flex;
          border-bottom: 1px solid #f4f4f4;
          padding: 15px 25px;
          box-sizing: border-box;

          .timeWrapper {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 30px;

            div {
              display: flex;
              align-items: center;
              gap: 7px;

              h5 {
                font-size: 14px;
                font-weight: 600;
              }
            }
          }

          .left,
          .right {
            width: 50%;
          }

          .right {
            border-left: 1px solid #f4f4f4;
            display: flex;
            flex-direction: column;
            padding: 20px 25px 30px 25px;
            box-sizing: border-box;
            align-items: flex-start;
            gap: 30px;

            button {
              border: 1px solid $--main-blue;
              padding: 7px 20px;
              font-size: 11px;
              border-radius: 5px;
              color: $--main-blue;
              transition: all 0.3s ease;

              &:hover {
                background: linear-gradient(
                  92.08deg,
                  #272b87 -11.22%,
                  #3990d9 145.9%
                );
                color: white;
              }
            }
          }
        }

        .bottom {
          display: flex;
          padding: 14px 17px;
          .medical {
            color: $--main-blue;
            background: white;
            border: 1px solid $--main-blue;
            border-radius: 5px;
            font-size: 12px;
            letter-spacing: 0.04em;
            padding: 9px 16px;
          }

          .details {
            color: white;
            background: linear-gradient(
              92.08deg,
              #272b87 -11.22%,
              #3990d9 145.9%
            );
            border-radius: 5px;
            font-size: 12px;
            letter-spacing: 0.04em;
            padding: 9px 16px;
          }
        }

        .bottomCenter {
          display: flex;
          padding: 13px 20px;
          box-sizing: border-box;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #f2f2f2;

          .imageWrapper {
            display: flex;
            gap: 6px;

            img {
              width: 40px;
              height: 40px;
              object-fit: cover;
              object-position: center;
            }

            .more {
              width: 40px;
              height: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              font-size: 10px;
              border: 1px dashed $--stroke-blue;

              span {
                font-size: 14px;
                font-weight: 700;
              }
            }
          }
        }
      }
    }
  }
}
