@import "../../assets/style/variables.module.scss";

.langWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  z-index: 100;
  position: relative;

  .countryFlag {
    width: 30px;
    height: 15px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .arrow {
    width: 15px;
    height: 15px;
    transform: rotate(-90deg);

    svg {
      width: 100%;
      height: 100%;
    }
  }

  select {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    top: 0;
    right: 0;
  }
}

@media only screen and (max-width: 940px) {
  .sidebarWrapper {
    margin-top: 20px;
    margin-bottom: 20px;
    align-self: flex-start;
    margin-left: 17px;
    .countryFlag {
      width: 50px;
      height: 25px;
    }
  }
}
