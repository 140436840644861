@import "../../../assets/style/variables.module.scss";

.wrapper {
  width: 100%;
  flex-direction: column;
  margin-top: 150px;

  .personWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

    h6 {
      font-size: 72px;
      font-family: $--cormorant;
      color: $--main-blue;
    }

    .person {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      &:nth-child(3) {
        flex-direction: row-reverse;
      }

      img {
        width: 500px;
      }

      .textWrapper {
        width: 40%;

        h5 {
          font-size: 62px;
          color: $--text-color;
          font-weight: 600;
        }

        h4 {
          font-size: 77px;
          font-family: $--cormorant;
          color: $--main-blue;
        }

        p {
          color: $--text-color;
          font-size: 16px;
          line-height: 24px;
          max-width: 500px;
          letter-spacing: 0.02em;
          margin-top: 24px;
        }
      }
    }
  }
}

@media only screen and (max-width: 940px) {
  .wrapper {
    .personWrapper {
      gap: 20px;
      margin-top: 20px;
      .person {
        width: 100%;
        flex-direction: column !important;

        .textWrapper {
          width: 100%;
          margin-top: 20px;

          h5 {
            font-size: 36px;
            text-align: center;
          }

          h4 {
            font-size: 48px;
            text-align: center;
          }
        }

        img {
          width: 100%;
        }
      }
    }
  }
}
