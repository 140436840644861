@import "../../assets/style/variables.module.scss";

.credit {
  background: #fafafa;
  min-height: 100vh;
  display: flex;

  .content {
    width: 100%;
    height: 100vh;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    padding: 128px 45px 45px 45px;
    box-sizing: border-box;

    iframe {
      height: 90vh;
    }
  }
}

@media only screen and (max-width: 940px) {
  .credit {
    .content {
      padding: 20px;
    }
  }
}
