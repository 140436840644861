@import "../../../assets/style/variables.module.scss";

.twoCard {
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .cardWrapper {
    margin-top: 50px;
    display: flex;

    .card {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .bodyWrapper {
        img {
          height: 300px;
        }
      }

      .iconWrapper {
        height: 300px;
        position: relative;
        width: 100%;

        .icon {
          width: 150px;
          height: 150px;
          border: 1px solid #dfe6ff;
          border-radius: 50%;
          padding: 40px;
          box-sizing: border-box;
          box-shadow: 0px 4px 102px rgba(96, 121, 213, 0.2);
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          background: white;
          transition: all 0.3s ease;

          svg {
            width: 100%;
            height: 100%;
            path {
              transition: all 0.3s ease;
            }
          }

          &:hover {
            background: linear-gradient(
              92.08deg,
              #272b87 -11.22%,
              #3990d9 145.9%
            );
            outline: 10px solid #ffffff;

            svg {
              path {
                fill: white;
              }
            }
          }
        }
      }

      .active {
        .icon {
          &:nth-child(1) {
            left: 0px;
            top: 30px;
            width: 140px;
            height: 140px;
            padding: 30px;
            transform: rotate(-20deg);
          }
          &:nth-child(2) {
            left: 140px;
            top: 0px;
            width: 80px;
            height: 80px;
            padding: 20px;
            transform: rotate(40deg);
          }
          &:nth-child(3) {
            left: 250px;
            top: 20px;
            width: 40px;
            height: 40px;
            padding: 5px;
            transform: rotate(20deg);
          }
          &:nth-child(4) {
            left: 370px;
            top: 20px;
            width: 120px;
            height: 120px;
            padding: 30px;
            transform: rotate(20deg);
          }
          &:nth-child(5) {
            left: 250px;
            top: 70px;
            width: 80px;
            height: 80px;
            padding: 20px;
            transform: rotate(20deg);
          }
          &:nth-child(6) {
            left: 170px;
            top: 90px;
            width: 50px;
            height: 50px;
            padding: 10px;
            transform: rotate(20deg);
          }
          &:nth-child(7) {
            left: 60px;
            top: 180px;
            width: 80px;
            height: 80px;
            padding: 10px;
            transform: rotate(20deg);
          }
          &:nth-child(8) {
            left: 180px;
            top: 170px;
            width: 100px;
            height: 100px;
            padding: 20px;
            transform: rotate(-20deg);
          }
          &:nth-child(9) {
            left: 400px;
            top: 160px;
            width: 120px;
            height: 120px;
            padding: 30px;
            transform: rotate(20deg);
          }
        }
      }

      h4 {
        margin-top: 50px;
        font-size: 28px;
        color: $--text-color;
        text-align: center;
        font-weight: 600;
      }
      p {
        color: #686868;
        font-size: 18px;
        font-weight: 400;
        margin-top: 35px;
        text-align: center;
        line-height: 127%;
      }
      &:first-child {
        padding-right: 50px;
        box-sizing: border-box;
        border-right: 1px solid rgba(223, 230, 255, 0.7);
      }
      &:last-child {
        padding-left: 50px;
        box-sizing: border-box;
      }
    }
  }
}

@media only screen and (max-width: 940px) {
  .twoCard {
    width: 100%;
    margin-top: 75px;
    .cardWrapper {
      flex-direction: column;
      width: 100%;
      gap: 30px;

      .card {
        width: 100%;
        overflow: hidden;
        padding: unset !important;
        border-right: unset !important;

        .iconWrapper {
          .icon {
            box-shadow: unset !important;
          }
        }
      }
    }
  }
}
