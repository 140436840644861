@import "../../assets/style//variables.module.scss";

.contentPopup {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba($color: $--main-blue, $alpha: 0.6);
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;

  .buttonWrapper {
    top: 100px;
    display: flex;
    margin-top: -50px;
    margin-bottom: 20px;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 20px;

    button {
      width: 30px;
      height: 30px;
      background: white;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .close {
    position: absolute;
    top: 50px;
    right: 50px;
    background: white;
    border: 1px solid $--stroke-blue;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    color: #ff6767;
  }

  .contentWrapper {
    height: 60%;

    img,
    video {
      height: 100%;
    }

    .pdfLink {
      display: flex;
      align-items: center;
      gap: 3px;
      align-items: center;
      color: $--main-blue;
      padding: 5px 12px;
      box-sizing: border-box;
      border-radius: 5px;
      font-weight: 600;
      font-size: 18px;
      background: $--secondary-blue;
      justify-content: center;
      height: 100%;
      color: white;
      width: 60vw;

      svg {
        width: 17px;
        height: 17px;

        path {
          stroke: white;
        }
      }
    }
  }
}

@media only screen and (max-width: 940px) {
  .contentPopup {
    .contentWrapper {
      width: 90%;
      height: auto;
      img,
      video {
        width: 100%;
        height: 100%;
      }

      .pdfLink {
        width: 90vw;
        height: 200px;
      }
    }
  }
}
