@import "../../assets/style/variables.module.scss";

.topBar {
  width: 80vw;
  position: fixed;
  top: 0;
  right: 0;
  padding: 25px 45px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  background: #fafafa;
  z-index: 10;
  border-bottom: 1px solid $--stroke-blue;

  label {
    display: flex;
    height: 45px;
    gap: 10px;
    width: 80%;

    input {
      height: 100%;
      background: white;
      border: 1px solid $--stroke-blue;
      padding-left: 20px;
      box-sizing: border-box;
      border-radius: 5px;
      width: 80%;
      box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.05);
      letter-spacing: 0.04em;

      &:focus {
        border: 1px solid $--main-blue;
      }

      &::placeholder {
        color: rgba(149, 154, 159, 0.49);
      }
    }

    button {
      width: 45px;
      height: 100%;
      background: linear-gradient(92.08deg, #272b87 -11.22%, #3990d9 145.9%);
      border-radius: 5px;
      padding: 7px;
      box-sizing: border-box;
      svg {
        path {
          fill: white;
        }
      }
    }
  }

  .right {
    display: flex;
    gap: 25px;
    align-items: center;

    .userCard {
      display: flex;
      align-items: center;
      gap: 10px;

      .info {
        display: flex;
        flex-direction: column;
        gap: 5px;
        letter-spacing: 0.05em;
        h6 {
          font-size: 10px;
          color: #686868;
          font-weight: 400;
        }

        h4 {
          font-weight: 600;
          color: $--text-color;
        }
      }

      img {
        width: 45px;
        height: 45px;
        box-sizing: border-box;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
        border: 1px solid $--stroke-blue;
      }
    }

    .buttonWrapper {
      display: flex;
      align-items: center;
      gap: 10px;

      .main {
        width: 30px;
        height: 30px;
        border: 1px solid $--stroke-blue;
        border-radius: 5px;
        padding: 5px;
        box-sizing: border-box;
        position: relative;

        .popupButton {
          position: absolute;
          width: 120px;
          right: -1000px;
          bottom: -50px;
          background: white;
          border: 1px solid $--stroke-blue;
          padding: 10px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 0.5s ease;

          button {
            position: relative;
            z-index: 1;
            font-weight: 500;
            color: $--main-blue;
            display: flex;
            align-items: center;
            gap: 5px;

            svg {
              width: 15px;
              height: 15px;

              path {
                fill: $--main-blue;
              }
            }
          }

          &::before {
            content: "";
            width: 150%;
            height: 150%;
            position: absolute;
            z-index: 0;
          }
        }

        &:hover {
          .popupButton {
            right: 0;
          }
        }

        &:hover {
          background: $--main-blue;
          svg {
            path {
              stroke: white;
            }
          }
        }

        svg {
          width: 100%;
          height: 100%;

          path {
            stroke: $--text-color;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 940px) {
  .topBar {
    width: 100vw;
    padding: 25px 30px;
    box-sizing: border-box;

    .headerBottom {
      position: absolute;
      height: 45px;
      background: #fafafa;
      // box-shadow: 0px 0px 22px 5px rgba(39, 43, 135, 0.12);
      width: 100%;
      left: 0;
      top: 80px;
      border-top: 1px solid #dfe6ff;
      display: flex;
      align-items: center;
      gap: 15px;
      padding-left: 25px;
      box-sizing: border-box;
      font-size: 12px;

      span {
        font-weight: 600;

        a {
          font-weight: 400;
          color: $--text-color;
        }
      }
    }

    label {
      display: none;
    }

    .right {
      .userCard {
        align-items: center;
        .info {
          display: none;
        }

        button {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 10px;
        }
      }
      .buttonWrapper {
        display: none;
      }
    }

    .logo {
      height: 35px;
      img {
        height: 100%;
      }
    }
  }
}
