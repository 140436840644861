@import "../../../assets/style/variables.module.scss";

.stepper {
  display: flex;
  gap: 15px;
  align-items: center;

  button {
    color: #a3a3a3;
    font-size: 14px;
    font-weight: 400;
  }

  .active {
    color: $--main-blue;
    background: #eaebff;
    padding: 6px 15px;
    border-radius: 5px;
  }

  .beforeAfter {
    color: $--main-blue;
    display: flex;
    align-items: center;
    gap: 7px;
  }

  .stroke {
    width: 20px;
    height: 1px;
    background: #a3a3a3;
  }

  .activeStroke {
    background: $--main-blue;
  }
}

@media only screen and (max-width: 940px) {
  .stepper {
    position: fixed;
    background: white;
    top: 0;
    left: 0;
    padding: 15px 20px;
    box-sizing: border-box;
    flex-wrap: wrap;
    z-index: 1;
    border-bottom: 1px solid $--main-blue;

    button {
      font-size: 10px;
    }
  }
}
