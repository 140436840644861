@import "../../../assets/style/variables.module.scss";

.operationCard {
  width: 60%;
  background: #efefef;
  border: 1px solid $--stroke-blue;
  border-radius: 5px;

  .cardWrapper {
    height: 460px;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 25px;
    box-sizing: border-box;
    padding-bottom: 20px;

    .card {
      width: 100%;
      display: flex;
      flex-direction: column;
      background: white;

      .top {
        display: flex;
        border-bottom: 1px solid #f4f4f4;

        .left,
        .right {
          width: 50%;
        }

        .left {
          display: flex;
          flex-direction: column;

          .doctor {
            display: flex;
            align-items: center;
            gap: 15px;
            height: 55px;
            border-top: 1px solid #f2f2f2;
            padding-left: 25px;
            box-sizing: border-box;

            .avatar {
              width: 35px;
              height: 35px;
              border: 1px solid $--stroke-blue;
              object-fit: cover;
              object-position: center;
              border-radius: 50%;
              align-items: center;
              display: flex;
              justify-content: center;
              font-size: 12px;
              color: white;
              background: $--main-blue;
              padding-top: 3px;
              box-sizing: border-box;
            }

            h5 {
              font-size: 12px;
              color: $--text-color;
              font-weight: 600;
            }

            img {
              width: 35px;
              height: 35px;
              border: 1px solid $--stroke-blue;
              object-fit: cover;
              object-position: center;
              border-radius: 50%;
            }
          }

          .operation {
            display: flex;
            gap: 15px;
            align-items: center;
            height: 55px;
            border-top: 1px solid #f2f2f2;
            padding-left: 25px;
            box-sizing: border-box;

            h5 {
              font-size: 12px;
              color: $--text-color;
              font-weight: 600;
            }

            .icon {
              width: 35px;
              height: 35px;
              border: 1px solid $--stroke-blue;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              background: white;
              padding: 5px;
              box-sizing: border-box;

              svg {
                path {
                  fill: $--main-blue;
                }
              }
            }
          }

          .flight {
            display: flex;
            gap: 15px;
            align-items: center;
            height: 55px;
            border-top: 1px solid #f2f2f2;
            padding-left: 25px;
            box-sizing: border-box;

            .icon {
              width: 35px;
              height: 35px;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .info {
              display: flex;
              flex-direction: column;
              gap: 4px;
              h6 {
                font-size: 12px;
                font-weight: 700;
                color: $--text-color;
              }

              p {
                font-size: 12px;
                color: $--text-color;
                font-weight: 400;
              }
            }
          }

          .location {
            display: flex;
            gap: 15px;
            align-items: center;
            border-top: 1px solid #f2f2f2;
            border-bottom: 1px solid #f2f2f2;
            padding-left: 25px;
            height: 55px;
            box-sizing: border-box;

            .icon {
              width: 35px;
              height: 35px;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .info {
              display: flex;
              flex-direction: column;
              gap: 4px;
              h6 {
                font-size: 12px;
                font-weight: 700;
                color: $--text-color;
              }

              p {
                font-size: 12px;
                color: $--text-color;
                font-weight: 400;
              }
            }
          }
        }

        .right {
          border-left: 1px solid #f4f4f4;
          display: flex;
          flex-direction: column;
          padding: 20px 25px 30px 25px;
          box-sizing: border-box;
          align-items: flex-start;
          gap: 30px;

          .timeWrapper {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 30px;

            div {
              display: flex;
              align-items: center;
              gap: 7px;

              h5 {
                font-size: 12px;
                font-weight: 600;
              }
            }
          }

          button {
            border: 1px solid $--main-blue;
            padding: 7px 20px;
            font-size: 11px;
            border-radius: 5px;
            color: $--main-blue;
            transition: all 0.3s ease;

            &:hover {
              background: linear-gradient(
                92.08deg,
                #272b87 -11.22%,
                #3990d9 145.9%
              );
              color: white;
            }
          }
        }
      }

      .bottom {
        display: flex;
        padding: 13px 25px;
        box-sizing: border-box;
        justify-content: space-between;
        align-items: center;

        .imageWrapper {
          display: flex;
          gap: 6px;

          .folderWrapper {
            width: 60px;
            height: 60px;
            border: 1px solid $--secondary-blue;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;

            svg {
              width: 20px;
              height: 20px;
              path {
                fill: $--secondary-blue;
              }

              &:last-child {
                path {
                  fill: $--main-blue;
                }
              }
            }
          }

          img {
            width: 60px;
            height: 60px;
            object-fit: cover;
            object-position: center;
          }

          .more {
            width: 60px;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            font-size: 10px;
            border: 1px dashed $--stroke-blue;

            span {
              font-size: 14px;
              font-weight: 700;
            }
          }
        }

        .details {
          color: white;
          background: linear-gradient(
            92.08deg,
            #272b87 -11.22%,
            #3990d9 145.9%
          );
          border-radius: 5px;
          font-size: 12px;
          letter-spacing: 0.04em;
          padding: 9px 16px;
        }
      }
    }
  }
}

@media only screen and (max-width: 940px) {
  .operationCard {
    width: 100%;
    margin-top: 250px;

    .cardWrapper {
      .card {
        width: 100%;
        display: flex;
        flex-direction: column;
        background: white;
        border-radius: 5px;

        .topCenter {
          display: flex;
          padding: 4px 25px;
          box-sizing: border-box;
          flex-wrap: wrap;
          gap: 10px;

          .doctor {
            display: flex;
            align-items: center;
            gap: 15px;
            height: 55px;
            box-sizing: border-box;

            .avatar {
              width: 35px;
              height: 35px;
              border: 1px solid $--stroke-blue;
              object-fit: cover;
              object-position: center;
              border-radius: 50%;
              align-items: center;
              display: flex;
              justify-content: center;
              font-size: 12px;
              color: white;
              background: $--main-blue;
              padding-top: 3px;
              box-sizing: border-box;
            }

            h5 {
              font-size: 12px;
              color: $--text-color;
              font-weight: 600;
            }

            img {
              width: 35px;
              height: 35px;
              border: 1px solid $--stroke-blue;
              object-fit: cover;
              object-position: center;
              border-radius: 50%;
            }
          }

          .operation {
            display: flex;
            gap: 15px;
            align-items: center;
            height: 55px;
            box-sizing: border-box;

            h5 {
              font-size: 12px;
              color: $--text-color;
              font-weight: 600;
            }

            .icon {
              width: 35px;
              height: 35px;
              border: 1px solid $--stroke-blue;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              background: white;
              padding: 5px;
              box-sizing: border-box;

              svg {
                path {
                  fill: $--main-blue;
                }
              }
            }
          }
        }

        .center {
          display: flex;
          padding: 4px 25px;
          box-sizing: border-box;
          gap: 10px;
          border-top: 1px solid #f2f2f2;
          border-bottom: 1px solid #f2f2f2;

          .flight {
            display: flex;
            gap: 15px;
            align-items: center;
            height: 55px;
            box-sizing: border-box;

            .icon {
              width: 35px;
              height: 35px;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .info {
              display: flex;
              flex-direction: column;
              gap: 4px;
              h6 {
                font-size: 12px;
                font-weight: 700;
                color: $--text-color;
              }

              p {
                font-size: 12px;
                color: $--text-color;
                font-weight: 400;
              }
            }
          }

          .location {
            display: flex;
            gap: 15px;
            align-items: center;
            height: 55px;
            box-sizing: border-box;

            .icon {
              width: 35px;
              height: 35px;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .info {
              display: flex;
              flex-direction: column;
              gap: 4px;
              h6 {
                font-size: 12px;
                font-weight: 700;
                color: $--text-color;
              }

              p {
                font-size: 12px;
                color: $--text-color;
                font-weight: 400;
              }
            }
          }
        }

        .top {
          display: flex;
          border-bottom: 1px solid #f4f4f4;
          padding: 15px 25px;
          box-sizing: border-box;

          .timeWrapper {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 30px;

            div {
              display: flex;
              align-items: center;
              gap: 7px;

              h5 {
                font-size: 14px;
                font-weight: 600;
              }
            }
          }

          .left,
          .right {
            width: 50%;
          }

          .right {
            border-left: 1px solid #f4f4f4;
            display: flex;
            flex-direction: column;
            padding: 20px 25px 30px 25px;
            box-sizing: border-box;
            align-items: flex-start;
            gap: 30px;

            button {
              border: 1px solid $--main-blue;
              padding: 7px 20px;
              font-size: 11px;
              border-radius: 5px;
              color: $--main-blue;
              transition: all 0.3s ease;

              &:hover {
                background: linear-gradient(
                  92.08deg,
                  #272b87 -11.22%,
                  #3990d9 145.9%
                );
                color: white;
              }
            }
          }
        }

        .bottom {
          display: flex;
          padding: 14px 17px;
          .medical {
            color: $--main-blue;
            background: white;
            border: 1px solid $--main-blue;
            border-radius: 5px;
            font-size: 12px;
            letter-spacing: 0.04em;
            padding: 9px 16px;
          }
        }

        .bottomCenter {
          display: flex;
          padding: 13px 20px;
          box-sizing: border-box;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #f2f2f2;

          .imageWrapper {
            display: flex;
            gap: 6px;

            img {
              width: 40px;
              height: 40px;
              object-fit: cover;
              object-position: center;
            }

            .more {
              width: 40px;
              height: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              font-size: 10px;
              border: 1px dashed $--stroke-blue;

              span {
                font-size: 14px;
                font-weight: 700;
              }
            }
          }

          .details {
            color: white;
            background: linear-gradient(
              92.08deg,
              #272b87 -11.22%,
              #3990d9 145.9%
            );
            border-radius: 5px;
            font-size: 12px;
            letter-spacing: 0.04em;
            padding: 9px 16px;
          }
        }
      }
    }
  }
}
