@import "../../assets/style/variables.module.scss";

.contact {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;

  .wrapper {
    width: 100%;
    max-width: 1200px;

    .info {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 50px;

      .left {
        ul {
          li {
            h4 {
              font-size: 12px;
              font-weight: 500;
              color: #979797;
              margin-bottom: 10px;
            }

            h5,
            a {
              font-size: 18px;
              color: $--main-blue;
              max-width: 390px;
              line-height: 24px;
              font-weight: 600;
              margin-bottom: 10px;
            }

            a {
              text-decoration: underline;
            }
          }
        }
      }

      .right {
        width: 55%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .download {
          background: $--main-blue;
          padding: 12px 24px;
          margin-top: 30px;
          border-radius: 5px;
          color: white;
        }

        .socialWrapper {
          display: flex;
          gap: 25px;
          margin-top: 35px;

          .social {
            border: 1px solid $--stroke-blue;
            padding: 10px 15px;
            display: flex;
            gap: 11px;
            align-items: center;
            border-radius: 10px;

            span {
              font-weight: 600;
            }

            &:nth-child(1) {
              span {
                color: #1877f2;
              }
            }
            &:nth-child(2) {
              span {
                color: #1da1f2;
              }
            }
            &:nth-child(3) {
              span {
                color: #000100;
              }
            }
          }
        }

        .frameWrapper {
          position: relative;
          filter: drop-shadow(-20px 0px 93px rgba(39, 43, 135, 0.08));
          width: 100%;

          &::before {
            content: "";
            width: 100%;
            height: 100%;
            background: #272b87;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 10px;
            mix-blend-mode: color;
            pointer-events: none;
          }

          iframe {
            width: 100%;
            height: 470px;
            border-radius: 10px;
          }
        }
      }
    }

    form {
      margin-top: 45px;

      h2 {
        color: $--text-color;
        font-size: 18px;
        margin-bottom: 15px;
      }

      .inputWrapper {
        display: flex;
        flex-direction: column;
        gap: 20px;
        span {
          color: rgb(211, 75, 75);
        }
      }

      .buttonWrapper {
        display: flex;
        flex-direction: column;
        gap: 25px;
        margin-top: 25px;
      }
    }
  }

  .langWrapper {
    position: fixed;
    bottom: 30px;
    right: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    z-index: 100;

    .countryFlag {
      width: 40px;
      height: 24px;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    .arrow {
      width: 15px;
      height: 15px;
      transform: rotate(-90deg);

      svg {
        width: 100%;
        height: 100%;
      }
    }

    select {
      position: absolute;
      opacity: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      top: 0;
      left: 0;
    }
  }

  .success {
    color: rgb(0, 111, 0);
    font-weight: 400;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 940px) {
  .contact {
    overflow-y: hidden;

    .wrapper {
      width: 90%;
      .info {
        flex-direction: column;

        textarea {
          width: 100% !important;
        }
        gap: 50px;
        .right {
          width: 100%;
          .socialWrapper {
            flex-direction: column;
          }

          .frameWrapper {
            width: 100%;
          }
        }
      }
    }
  }
}
