@import "../../../assets/style/variables.module.scss";

.sidebar {
  width: 20vw;
  min-width: 20vw;
  background: white;
  height: 100vh;
  border-right: 1px solid $--stroke-blue;
  box-shadow: 10px 0px 32px rgba(39, 43, 135, 0.06);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding: 45px 25px 30px 25px;
  box-sizing: border-box;
  position: relative;

  .top {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  img {
    height: 46px;
  }

  .menu {
    margin-top: 90px;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;

    a {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 12px 16px 12px 20px;
      box-sizing: border-box;
      gap: 10px;
      color: $--main-blue;
      svg {
        width: 20px;
        height: 20px;
        path {
          fill: $--main-blue;
        }
      }
      span {
        font-size: 14px;
      }
    }

    .active {
      background: linear-gradient(92.08deg, #272b87 -11.22%, #3990d9 145.9%);
      border: 1px solid $--stroke-blue;
      border-radius: 5px;
      color: white;

      svg {
        path {
          fill: white;
        }
      }
    }
  }

  .signOut {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 12px 16px 12px 20px;
    box-sizing: border-box;
    gap: 10px;
    color: $--main-blue;
    svg {
      width: 20px;
      height: 20px;
      path {
        fill: $--main-blue;
      }
    }
    span {
      font-size: 14px;
    }
  }
}

.shadowButton {
  display: none;
}

@media only screen and (max-width: 940px) {
  .close {
    display: none !important;
  }
  .sidebar {
    position: fixed;
    width: 70%;
    z-index: 5;
    left: 0;
    top: 0;
    overflow-y: scroll;
    padding-top: 100px;

    height: 100%;
    .menu {
      margin-top: 30px;
    }
  }

  .mobileSearch {
    display: flex;
    height: 45px;
    gap: 10px;
    width: 80%;
    margin-top: 20px;

    input {
      height: 100%;
      background: white;
      border: 1px solid $--stroke-blue;
      padding-left: 20px;
      box-sizing: border-box;
      border-radius: 5px;
      width: 80%;
      box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.05);
      letter-spacing: 0.04em;

      &:focus {
        border: 1px solid $--main-blue;
      }

      &::placeholder {
        color: rgba(149, 154, 159, 0.49);
      }
    }

    button {
      width: 45px;
      height: 100%;
      background: linear-gradient(92.08deg, #272b87 -11.22%, #3990d9 145.9%);
      border-radius: 5px;
      padding: 7px;
      box-sizing: border-box;
      svg {
        path {
          fill: white;
        }
      }
    }
  }

  .shadowButton {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 4;
    background: rgba($color: #000000, $alpha: 0.4);
    width: 100vw;
    height: 100vh;
  }
}
