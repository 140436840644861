@import "../../../assets/style/variables.module.scss";

.doc {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  flex-direction: column;
  background: rgba($color: #000000, $alpha: 0.5);
  z-index: 100;

  .bar {
    width: 70vw;
    background: $--main-blue;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    box-sizing: border-box;

    h5 {
      color: white;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 0.05em;
    }

    button {
      color: white;
    }
  }

  iframe {
    height: 90vh;
    width: 70vw;
  }
}

.footer {
  position: absolute;
  bottom: 50px;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 50px;

  .logo {
    display: flex;
    gap: 23px;
    align-items: center;

    h5 {
      font-size: 10px;
      color: #7d7d7d;
      font-weight: 300;
    }
  }

  .link {
    display: flex;
    gap: 14px;

    button {
      color: #7d7d7d;
    }
  }
}

@media only screen and (max-width: 940px) {
  .footer {
    width: 90%;
    padding: 0;
    flex-direction: column-reverse;
    gap: 30px;
    position: static;
    margin-top: 60px;
    margin-bottom: 30px;
  }
}
