@import '../../../assets/style/variables.module.scss';

.doctorFeedback {
  width: 50%;
  background: #efefef;
  border: 1px solid $--stroke-blue;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 20px 0 20px;
  box-sizing: border-box;
  height: 357px;
  overflow: scroll;

  .title {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
    h2 {
      color: $--text-color;
      font-size: 14px;
      font-weight: 600;
    }
    h3 {
      color: #959a9f;
      font-size: 11px;
      font-weight: 400;
    }
  }

  .cardWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
    padding-bottom: 20px;

    .card {
      background: white;
      display: flex;
      box-sizing: border-box;
      padding: 17px 54px 19px 35px;
      border-radius: 5px;
      align-items: center;
      justify-content: space-between;

      .left {
        display: flex;
        flex-direction: column;
        gap: 15px;
        .doctor {
          display: flex;
          align-items: center;
          gap: 15px;

          img {
            width: 35px;
            height: 35px;
            border: 1px solid $--stroke-blue;
            border-radius: 50%;
          }

          h5 {
            font-size: 12px;
            color: $--text-color;
            font-weight: 600;
          }
        }
        .operation {
          display: flex;
          align-items: center;
          gap: 15px;

          .icon {
            width: 35px;
            height: 35px;
            border: 1px solid $--stroke-blue;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          h5 {
            font-size: 12px;
            color: $--text-color;
            font-weight: 600;
          }
        }
        .flight {
          display: flex;
          align-items: center;
          gap: 15px;

          .icon {
            width: 35px;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .info {
            display: flex;
            flex-direction: column;
            gap: 4px;
            h6 {
              font-size: 12px;
              font-weight: 700;
              color: $--text-color;
            }

            p {
              font-size: 12px;
              color: $--text-color;
              font-weight: 400;
            }
          }
        }
      }

      .right {
        display: flex;
        border: 1px solid $--stroke-blue;
        padding: 20px 35px;
        box-sizing: border-box;
        gap: 18px;
        border-radius: 10px;

        .active {
          svg {
            path {
              filter: drop-shadow(0px 0px 2px rgba(255, 221, 103, 0.75));
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 940px) {
  .doctorFeedback {
    width: 100%;

    .card {
      flex-direction: column;

      .right {
        margin-top: 20px;
        margin-left: 20px;
      }
    }
  }
}
