@import "../../assets/style/variables.module.scss";

.wrapper {
  position: fixed;
  z-index: 999;
  top: 95px;
  width: 100vw;
  height: 5px;
}

.progress {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  height: 100%;
  color: rgb(var(--pure-material-primary-rgb, 33, 150, 243));
  background-color: rgba(var(--pure-material-primary-rgb, 33, 150, 243), 0.12);
  font-size: 16px;
  width: 100%;
}

.progressBar::-webkit-progress-bar {
  background-color: transparent;
}

/* Determinate */
.progressBar::-webkit-progress-value {
  background-color: currentColor;
  transition: all 0.2s;
}

.progressBar::-moz-progress-bar {
  background-color: currentColor;
  transition: all 0.2s;
}

.progressBar::-ms-fill {
  border: none;
  background-color: currentColor;
  transition: all 0.2s;
}

/* Indeterminate */
.progressBar:indeterminate {
  background-size: 200% 100%;
  background-image: linear-gradient(
    to right,
    transparent 50%,
    currentColor 50%,
    currentColor 60%,
    transparent 60%,
    transparent 71.5%,
    currentColor 71.5%,
    currentColor 84%,
    transparent 84%
  );
  animation: progressBar 2s infinite linear;
}

.progressBar:indeterminate::-moz-progress-bar {
  background-color: transparent;
}

.progressBar:indeterminate::-ms-fill {
  animation-name: none;
}

@keyframes pure-material-progress-linear {
  0% {
    background-size: 200% 100%;
    background-position: left -31.25% top 0%;
  }
  50% {
    background-size: 800% 100%;
    background-position: left -49% top 0%;
  }
  100% {
    background-size: 400% 100%;
    background-position: left -102% top 0%;
  }
}
