@import "../../assets/style/variables.module.scss";

.pageWrapper {
  background: #fafafa;
  min-height: 100vh;
  display: flex;
  .content {
    width: 100%;
    height: 100vh;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    padding: 128px 45px 45px 45px;
    box-sizing: border-box;
  }
}

.calendar {
  width: 100%;
}

.headPart {
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 40px;
  box-sizing: border-box;
  border-radius: 10px 10px 0 0;
  border: 1px solid $--stroke-blue;
  min-height: 50px;

  .leftPart {
    display: flex;
    align-items: center;
    gap: 10px;

    span {
      width: 20px;
      height: 20px;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    .buttonWrapper {
      display: flex;

      button {
        width: 15px;
        height: 15px;

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .headInfo {
    display: flex;
    align-items: center;
    gap: 50px;

    .disabled {
      opacity: 0.5;
    }

    div {
      position: relative;
      display: flex;
      align-items: center;
      font-weight: 500;
      color: $--text-color;
      cursor: pointer;

      &::before {
        content: "";
        width: 20px;
        height: 20px;

        border-radius: 50%;
        position: absolute;
        left: -30px;
      }
    }

    .flight {
      &::before {
        background: #d5897e;
      }
    }
    .accomdation {
      &::before {
        background: #3ca8ec;
      }
    }
    .operation {
      &::before {
        background: linear-gradient(92.08deg, #272b87 -11.22%, #3990d9 145.9%);
      }
    }
  }

  p {
    font-size: 14px;
    color: $--text-color;
    font-weight: 700;
  }

  .right {
    svg {
      transform: rotate(180deg);
    }
  }
}

.weekWrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  height: 43px;
  box-sizing: border-box;
  border-left: 1px solid $--stroke-blue;
  border-right: 1px solid $--stroke-blue;

  .weekRow {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #959a9f;
    height: 100%;

    &:not(:last-child) {
      border-right: 1px solid $--stroke-blue;
    }
  }
}

.dayWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid $--stroke-blue;
  box-sizing: border-box;
  padding: 45px 40px;
  background: white;

  //   grid-template-rows:

  .dayWeek {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    border-bottom: 1px solid $--stroke-blue;
    border-left: 1px solid $--stroke-blue;
    border-right: 1px solid $--stroke-blue;
    border-top: 1px solid $--stroke-blue;
  }

  .dayCell {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 145px;
    position: relative;
    cursor: pointer;

    &:not(:first-child) {
      border-left: 1px solid $--stroke-blue;
    }

    .dayInfo {
      position: absolute;
      top: 10px;
      left: 10px;
      width: calc(100% - 20px);
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .dayContent {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      height: 50%;
      position: absolute;
      border: 1px solid white;
      justify-content: center;
      h4 {
        font-size: 10px;
        color: white;
        font-weight: 400;
        text-align: center;
      }

      h5 {
        font-size: 12px;
        font-weight: 500;
        text-align: center;
        color: white;
        margin-top: 3px;
      }

      span {
        svg {
          width: 10px;
          height: 10px;

          path {
            fill: white;
          }
        }
      }
    }
  }

  .active {
    .dayInfo {
      span {
        color: white;
      }
    }
  }

  .nextMonth {
    color: #e0e0e0;
  }

  .today {
    color: white;

    span {
      background: $--main-blue;
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
    }
  }

  .operationDay {
    background: $--main-blue;
  }
  .flightDay {
    background: $--secondary-orange;

    .dayInfo {
      span {
        color: white;
      }
    }
  }
  .hotelDay {
    background: $--secondary-blue;

    .dayInfo {
      span {
        color: white;
      }
    }
  }

  .default {
    color: #959a9f;
  }

  .greenCircle {
    width: 10px;
    height: 10px;
    background: #0eb250;
    position: absolute;
    right: 10px;
    top: 10px;
    border-radius: 50%;
  }
}

.popupClick {
  position: absolute;
  background: #3ca8ec;
  bottom: 0;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  right: 0;

  width: 100%;
  height: 100%;
  z-index: 1;
  padding: 15px;
  box-sizing: border-box;
  gap: 10px;

  button {
    display: flex;
    align-items: center;
    background: white;
    border-radius: 5px;
    padding: 5px 7px;
    box-sizing: border-box;
    font-size: 14px;
    color: #11405e;
    font-weight: 500;

    svg {
      margin-right: 5px;
      width: 16px;
      height: 16px;
      path {
        fill: #3ca8ec;
      }
    }

    &:nth-child(1) {
      svg {
        path {
          fill: $--main-blue;
        }
      }
    }

    &:nth-child(2) {
      svg {
        path {
          fill: #d5897e;
        }
      }
    }

    &:nth-child(3) {
      svg {
        path {
          fill: #3ca8ec;
        }
      }
    }
  }
}

@media only screen and (max-width: 1250px) {
  .popupClick {
    button {
      span {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 940px) {
  .reminders {
    padding: 30px 30px 0 30px;

    .titleWrapper {
      display: flex;
      justify-content: space-between;

      h2 {
        font-size: 14px;
        font-weight: 400;
        color: $--text-color;
      }

      h3 {
        font-size: 16px;
        font-weight: 600;
        color: $--main-blue;
      }
    }

    .cardWrapper {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .flight {
        background: #d9919e;
      }
      .accomdation {
        background: #87c3ff;
      }
      .operation {
        background: linear-gradient(92.08deg, #272b87 -11.22%, #3990d9 145.9%);
      }
      .card {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 10px;
        box-sizing: border-box;
        border-radius: 10px;

        .cardHead {
          width: 100%;
          display: flex;
          justify-content: space-between;

          h3 {
            color: white;
            font-size: 14px;
            font-weight: 400;
          }

          .numberWrapper {
            display: flex;
            gap: 5px;

            .number {
              width: 25px;
              height: 25px;
              background: white;
              color: $--text-color;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 3px;
            }
          }
        }

        .cardBottom {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          margin-top: 10px;
          flex-direction: column;
          gap: 10px;

          .cardButtonWrapper {
            display: flex;
            gap: 6px;
          }

          .userInfo {
            display: flex;
            align-items: center;
            gap: 10px;

            .avatar {
              width: 35px;
              height: 35px;
              border: 1px solid $--stroke-blue;
              object-fit: cover;
              object-position: center;
              border-radius: 50%;
              align-items: center;
              display: flex;
              justify-content: center;
              font-size: 12px;
              color: $--main-blue;
              background: white;
              padding-top: 3px;
              box-sizing: border-box;
            }

            img {
              width: 35px;
              height: 35px;
              object-fit: cover;
              object-position: center;
              border-radius: 20px;
            }
            div {
              display: flex;
              flex-direction: column;
              gap: 5px;

              h4 {
                font-size: 12px;
                color: white;
                font-weight: 500;
              }
              h5 {
                font-size: 12px;
                color: white;
                font-weight: 500;
              }
            }
          }
        }
      }
    }

    .bottomButton {
      width: 100%;
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      button {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        background: $--secondary-orange;

        &:nth-child(2) {
          background: $--secondary-blue;
        }
        &:nth-child(3) {
          background: $--main-blue;
        }
        svg {
          path {
            fill: white;
          }
        }
        span {
          display: block;
          text-align: left;
          margin-left: 10px;
        }
      }
    }

    .buttonWrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      background: #f0f0f0;
      height: 35px;
      margin-top: 20px;
      margin-bottom: 25px;
      border-radius: 20px;

      .active {
        background: white;
        border: 1px solid $--stroke-blue;
        border-radius: 20px;
        width: 60%;
      }

      button {
        width: 33%;
      }
    }
  }

  .pageWrapper {
    .content {
      padding: 128px 0;
      .calendar {
        .headPart {
          padding: 5px 40px;
          justify-content: center;
          .leftPart {
            display: none;
          }

          .headInfo {
            width: 100%;
            justify-content: center;
            .flight {
              font-size: 12px;
            }
            .accomdation {
              font-size: 12px;
            }
            .operation {
              font-size: 12px;
            }

            button {
              display: none;
            }
          }
        }
        .dayWrapper {
          padding: 30px;
          background: #f3f3f3;

          .today {
            span {
              background: $--stroke-blue;
              color: $--main-blue !important;
              padding: 7px;
            }
          }

          // .active {
          //   background: $--main-blue;
          // }

          .activeMobile {
            background: $--secondary-blue;

            span {
              color: white !important;
            }
          }

          .orangeCircle {
            width: 10px;
            height: 10px;
            background: $--secondary-orange;
            position: absolute;
            right: 10px;
            top: 10px;
            border-radius: 50%;
          }
          .blueCircle {
            width: 10px;
            height: 10px;
            background: $--main-blue;
            position: absolute;
            right: 10px;
            top: 10px;
            border-radius: 50%;
          }
          .secondaryBlueCircle {
            width: 10px;
            height: 10px;
            background: $--secondary-blue;
            position: absolute;
            right: 10px;
            top: 10px;
            border-radius: 50%;
          }

          .dayHead {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;

            .dayHeadWrapper {
              width: 60%;
              display: flex;
              justify-content: space-between;
              align-items: center;

              h2 {
                font-size: 14px;
                font-weight: 500;
                color: $--text-color;
              }

              .right {
                transform: rotate(180deg);
              }
            }
          }
          .dayWeek {
            border: unset;

            .active {
              .dayInfo {
                span {
                  color: unset;
                }
              }
            }
            .dayCell {
              height: 50px;

              .dayContent {
                display: none;
              }

              &:not(:first-child) {
                border: unset;
              }

              .dayInfo {
                width: 100%;
                height: 100%;
                position: static;
                display: flex;
                align-items: center;
                justify-content: center;
                span {
                  &:nth-child(2) {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .popupClick {
    height: auto;
    bottom: unset;
    top: 0;
    display: none;
  }

  .flightbutton {
    background: $--secondary-orange!important;
  }

  .hotelbutton {
    background:  $--secondary-blue!important;
  }

  .operationbutton {
    background: $--main-blue!important; 
  }
}
