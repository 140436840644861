@import "../../assets/style/variables.module.scss";

.authButton {
  width: 400px;
  background: transparent;
  border: 1px solid $--main-blue;
  height: 60px;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 600;
  color: $--main-blue;
  letter-spacing: 0.07em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.submit {
  background: $--main-blue;
  color: white;
}

.load {
  background: grey;
  border-color: rgb(66, 66, 66);
}

.disabled {
  background: grey;
  border-color: rgb(66, 66, 66);
  color: rgb(164, 164, 164);
}

.ldsRipple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  scale: 0.6;
}
.ldsRipple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: ldsRipple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.ldsRipple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes ldsRipple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

@media only screen and (max-width: 940px) {
  .authButton {
    width: 100%;
  }
}
