@import "../../../assets/style/variables.module.scss";

.inputWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 76px;
  gap: 16px;

  .labelWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    background: white;
    padding: 15px 0;
    box-sizing: border-box;
    z-index: 2;
    position: relative;
    box-shadow: 4px 4px 89px rgba(0, 0, 0, 0.1);
    border-radius: 10px;

    select {
      background: transparent;
      width: 100%;
    }

    .date {
      position: relative;
      input[type="date"]::-webkit-calendar-picker-indicator {
        background: transparent;
        bottom: 0;
        color: transparent;
        cursor: pointer;
        height: auto;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
      }

      .dateWrapper {
        width: 100% !important;
        height: 100% !important;
        opacity: 0;
        position: absolute;

        div {
          width: 100% !important;
          height: 100% !important;
          opacity: 0;
        }
      }

      input {
        background: red;
        position: absolute;
        width: 100%;
        height: 50px;
        left: 0;
        opacity: 0;
      }
    }

    label {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 25px;
      padding-left: 30px;
      box-sizing: border-box;

      h5 {
        font-weight: 300;
      }

      &:not(:last-child) {
        border-right: 1px solid #d0d0d0;
      }
    }
  }

  a {
    width: 76px;
    height: 100%;
    background: white;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
    box-shadow: 4px 4px 89px rgba(0, 0, 0, 0.1);
  }
}

.featuredDoctor {
  padding-top: 60px;
  position: relative;
  width: 100%;
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .cardWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    width: 100%;
    gap: 30px;
    box-sizing: border-box;
    margin-top: 70px;
  }

  .doctorCard {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: transparent;

    img {
      height: 300px;
      width: 99.5%;
      border: 1px solid #dfe6ff;
      border-radius: 10px 10px 0px 0px;
      bottom: -1px;
      position: relative;
      object-fit: cover;
      object-position: center;
    }

    .info {
      background: #ffffff;
      border-radius: 0px 0px 20px 20px;
      border: 1px solid #dfe6ff;
      width: 100%;
      display: flex;
      padding: 25px 18px;
      box-sizing: border-box;
      gap: 15px;
      flex-direction: column;

      .name {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h3 {
          font-size: 16px;
          font-weight: 700;
          color: $--text-color;
        }

        .country {
          display: flex;
          align-items: center;
          gap: 7px;
          font-size: 16px;

          color: $--text-color;

          svg {
            width: 20px;
            height: 14px;
          }
        }
      }

      .operationCard {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 8px;

        p {
          border: 1px solid #dfe6ff;
          position: relative;
          padding: 8px 12px;
          font-size: 12px;
          border-radius: 7px;
          color: $--text-color;
        }

        button {
          border: 1px solid $--main-blue;
          position: relative;
          padding: 6px 12px;
          font-size: 12px;
          border-radius: 7px;
          color: $--main-blue;
          font-weight: 600;
          box-sizing: border-box;
        }
      }

      .bottomWrapper {
        border-top: 1px solid $--stroke-blue;
        padding-top: 15px;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-end;
        button {
          font-size: 14px;
          background: linear-gradient(
            92.08deg,
            #272b87 -11.22%,
            #3990d9 145.9%
          );
          color: white;
          padding: 9px 16px 7.5px 16px;
          border-radius: 3px;
        }
      }
    }
  }

  .more {
    color: $--main-blue;
    font-size: 18px;
    margin-top: 40px;

    span {
      text-decoration: underline;
      font-size: 24px;
      font-weight: 700;
    }
  }
}

@media only screen and (max-width: 940px) {
  .inputWrapper {
    height: unset;
    flex-direction: column;

    a {
      width: 100%;
      height: 50px;
    }

    .labelWrapper {
      flex-direction: column;
      padding: 10px 0;
      label {
        width: 100%;
        height: 50px;

        &:first-child {
          border-bottom: 1px solid #d0d0d0;
        }

        &:not(:last-child) {
          border-right: unset;
        }
      }
    }
  }

  .featuredDoctor {
    padding-top: 35px;
    margin-top: 35px;
    .cardWrapper {
      margin-top: 20px;
      grid-template-columns: 1fr;
    }

    .doctorCard {
      .info {
        .name {
        }

        .operationCard {
          width: 100%;
          p {
            font-size: 14px;
            text-align: center;
          }

          button {
            width: 100%;
            font-size: 16px;
          }
        }

        .bottomWrapper {
          align-items: center;
          justify-content: center;

          button {
            width: 100%;
          }
        }
      }
    }

    .more {
      font-size: 16px;

      span {
        font-size: 18px;
      }
    }
  }
}
