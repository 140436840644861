@import "../../../assets/style/variables.module.scss";

.topbar {
  width: 100%;
  display: flex;
  justify-content: space-between;

  select {
    border: 1px solid $--stroke-blue;
    color: #686868;
    font-size: 14px;
    padding: 7px 30px 7px 10px;
    box-sizing: border-box;
    border-radius: 10px;
    letter-spacing: 0.02em;
    font-weight: 500;
  }

  .buttonWrapper {
    display: flex;
    gap: 15px;
    button {
      width: 35px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 3px;
      border: 1px solid $--stroke-blue;

      svg {
        path {
          stroke: #454545;
        }
      }
    }

    .active {
      background: linear-gradient(
        92.08deg,
        #272b87 -11.22%,
        #3990d9 145.9%
      ) !important;

      svg {
        path {
          stroke: unset;
          fill: white;
        }
      }
    }
  }

  .filter {
    display: none;
  }
}

@media only screen and (max-width: 940px) {
  .topbar {
    select {
      display: none;
    }

    .filter {
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(92.08deg, #272b87 -11.22%, #3990d9 145.9%);
      padding: 0 30px;
      border-radius: 5px;
      color: white;
    }
  }
}
