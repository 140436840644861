@import "../../assets/style/variables.module.scss";

.searchBody {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background: #fafafa;
  position: relative;

  .topButtonWrapper {
    position: absolute;
    top: 98px;
    z-index: 10;
    height: 45px;
    border: 1px solid $--main-blue;
    width: 100%;
    display: flex;

    button {
      width: 100%;
      color: $--main-blue;
      font-weight: 600;
      background: white;
    }

    .topActive {
      background: linear-gradient(92.08deg, #272b87 -11.22%, #3990d9 145.9%);
      color: white;
    }
  }

  .ghostButton {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
  }

  .selectedCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 340px;
    position: absolute;
    right: 10vh;
    z-index: 1000;

    h2 {
      width: 100%;
      font-size: 24px;
      color: $--text-color;
      background: white;
      border: 1px solid $--stroke-blue;
      padding: 10px 0;
      text-align: center;
      border-radius: 5px;
      letter-spacing: 0.05em;
      font-weight: 700;
    }

    .checkWrapper {
      height: 275px;
      width: 100%;
      background: white;
      border: 1px solid $--stroke-blue;
      padding: 20px 25px;
      box-sizing: border-box;
      border-radius: 5px;
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      overflow: scroll;

      button {
        display: flex;
        align-items: center;
        gap: 10px;
        height: 60px;
        min-height: 40px;
        border: 1px solid $--stroke-blue;
        padding: 0 15px;
        border-radius: 5px;
        h5 {
          font-size: 15px;
          font-weight: 500;
        }
      }

      .partActive {
        background: linear-gradient(92.08deg, #272b87 -11.22%, #3990d9 145.9%);
        color: white;
      }
    }

    .checkSubmit {
      border-radius: 5px;
      margin-top: 10px;
      width: 100%;
      height: 41px;
      color: white;
      background: linear-gradient(92.08deg, #272b87 -11.22%, #3990d9 145.9%);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.moveBody {
  transform: translateX(-15vw);
}

.bodyWrapper {
  position: relative;
  transition: transform 0.5s ease;

  .body {
    position: relative;
    width: 15vw;
    height: 80vh;
    border: 1px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;

    @keyframes example {
      0% {
        background: transparent;
        border-radius: 0;
      }
      20% {
        background: rgba($color: $--main-blue, $alpha: 0.3);
        border-radius: 50px;
      }
      40% {
        background: transparent;
        border-radius: 0;
      }
      60% {
        background: rgba($color: $--main-blue, $alpha: 0.3);
        border-radius: 50px;
      }
      100% {
        background: transparent;
        border-radius: 0;
      }
    }

    .headButton {
      position: absolute;
      width: 6vw;
      height: 13vh;
      border: 1px solid transparent;
      top: 0;
      z-index: 10;
      animation-name: example;
      animation-duration: 3s;
    }

    .upperButton {
      position: absolute;
      width: 7vw;
      height: 14vh;
      border: 1px solid transparent;
      top: 13vh;
      z-index: 10;
      animation-name: example;
      animation-duration: 3s;
    }
    .bottomButton {
      position: absolute;
      width: 10vw;
      height: 22vh;
      border: 1px solid transparent;
      top: 26vh;
      z-index: 10;
      animation-name: example;
      animation-duration: 3s;
    }

    .legButton {
      position: absolute;
      width: 10vw;
      height: 37vh;
      border: 1px solid transparent;
      top: 42vh;
      z-index: 10;
      animation-name: example;
      animation-duration: 3s;
    }

    .handButton1 {
      position: absolute;
      width: 3vw;
      height: 37vh;
      border: 1px solid transparent;
      top: 10vh;
      left: 0;
      z-index: 10;
      transform: rotate(10deg);
      animation-name: example;
      animation-duration: 3s;
    }

    .handButton2 {
      position: absolute;
      width: 3vw;
      height: 37vh;
      border: 1px solid transparent;
      top: 10vh;
      right: 0;
      z-index: 10;
      transform: rotate(-10deg);
      animation-name: example;
      animation-duration: 3s;
    }

    img {
      position: absolute;
      object-position: center;
      height: 100%;

      transition: scale 0.5s ease, transform 0.5s ease;
    }
  }

  .circle {
    position: absolute;
    width: 10vh;
    height: 10vh;
    filter: blur(30.5px);
    background: rgba($color: #272b87, $alpha: 0.6);
    top: 10px;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 0;
    border-radius: 50%;
    transition: all 0.3s ease;
    opacity: 0.5;
  }

  .hand {
    width: 3vw !important;
    height: 45vh !important;
    top: 4vh !important;
    right: 12vw !important;
    transform: rotate(10deg) !important;
  }

  .circle2 {
    position: absolute;
    width: 3vw;
    height: 45vh;
    background: transparent;
    filter: blur(30.5px);
    background: rgba($color: #272b87, $alpha: 0.6);
    top: 4vh;
    right: 0;
    z-index: 0;
    border-radius: 50%;
    transition: all 0.3s ease;
    opacity: 0.5;
    transform: rotate(-10deg);
  }

  .upper {
    transform: translateY(13vh);
    width: 14vw;
    height: 11vh;
  }

  .bottom {
    transform: translateY(25vh);
    width: 8vw;
    height: 20vh;
  }

  .leg {
    transform: translateY(40vh);
    width: 10vw;
    height: 24vh;
  }

  .head-zoom {
    scale: 4;
    transform: translateY(30vh);
  }
  .upper-zoom {
    scale: 4;
    transform: translateY(20vh);
  }
  .hand-zoom {
    scale: 3;
    transform: translate(-10vh, 10vh);
  }
  .leg-zoom {
    scale: 2;
    transform: translate(0, -15vh);
  }

  .bottom-zoom {
    scale: 4;
    transform: translate(0, 0vh);
  }
}

@media only screen and (max-width: 940px) {
  .searchBody {
    align-items: center !important;
    max-height: 100%;
    overflow: hidden;
    height: 100%;

    .topButtonWrapper {
      height: 40px;
      top: 96px;

      button {
        font-size: 12px;
      }
    }

    .selectedCard {
      right: 0;
      bottom: 0px;
      width: 100%;

      .checkWrapper {
        width: 100%;
        display: flex;
        height: unset;
        margin-top: 0;

        .checkInnerWrapper {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 20px;
          max-height: 150px;

          .row {
            display: flex;
            flex-direction: column;
            gap: 20px;
            padding-bottom: 20px;
          }
        }
      }

      // .checkWrapper {
      //   flex-direction: row;
      //   flex-wrap: wrap;
      //   overflow: scroll;
      //   align-items: flex-start;
      //   justify-content: flex-start;
      //   width: 500px;
      //   margin-top: 0;
      //   height: 150px;
      //   overflow: scroll;
      // }

      .checkSubmit {
        height: 45px;
        border-radius: 0px;
        margin-top: 0;
      }
    }

    .moveBody {
      transform: translateX(0);
    }

    .bodyWrapper {
      margin-top: 150px;
      .body {
        width: 45vw;

        .headButton {
          width: 15vw;
        }

        .handButton1,
        .handButton2 {
          width: 10vw;
        }

        .handButton1 {
        }

        .upperButton {
          width: 27vw;
        }

        .legButton {
          width: 24vw;
        }

        .bottomButton {
          width: 30vw;
        }
      }
    }
  }
}
