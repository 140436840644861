@import "../../../assets/style/variables.module.scss";

.optionCard {
  background: #efefef;
  width: 50%;
  border: 1px solid $--stroke-blue;
  border-radius: 5px;
  padding: 20px 20px;
  box-sizing: border-box;
  height: 357px;
  overflow: scroll;
  display: flex;
  align-items: center;
}

.cardWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  padding-bottom: 20px;
}

.card {
  width: 100%;
  background: white;
  border: 1px solid $--stroke-blue;
  border-radius: 5px;
  padding: 20px 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 25px;

  &:last-child {
    margin-bottom: 20px;
  }

  a {
    color: white;
    background: linear-gradient(92.08deg, #272b87 -11.22%, #3990d9 145.9%);
    border-radius: 5px;
    font-size: 12px;
    letter-spacing: 0.04em;
    padding: 9px 16px;
  }

  .infoWrapper {
    display: flex;
    gap: 30px;
    width: 100%;
    align-items: center;

    .info {
      display: flex;
      gap: 20px;
      align-items: center;

      div {
        display: flex;
        gap: 7px;

        h4 {
          font-size: 12px;
          color: $--text-color;
        }

        h5 {
          font-size: 12px;
          color: $--text-color;
          font-weight: 400;
        }
      }
    }
  }

  h3 {
    font-size: 18px;
    color: $--main-blue;
    font-weight: 600;
    width: 100%;
  }
}

@media only screen and (max-width: 940px) {
  .optionCard {
    width: 100%;
  }
}
