@import '../../../assets/style/variables.module.scss';

.faq {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 100px;

  .title {
    color: $--main-blue;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h5 {
      font-size: 25px;
      font-weight: 400;
    }
    h3 {
      font-size: 42px;
      font-weight: 600;
      margin-top: 30px;
    }
  }

  .cardWrapper {
    margin-top: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    .card {
      width: 100%;
      padding: 35px 0;
      border-bottom: 1px solid #cfcfcf;
      .top {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h4 {
          font-size: 20px;
          color: $--text-color;
          font-weight: 600;
        }

        button {
          svg {
            transition: all 0.4s ease;
          }
        }
      }

      p {
        font-size: 18px;
        color: #686868;
        font-weight: 400;
        margin-top: 30px;
        display: none;
      }
    }

    .active {
      .top {
        button {
          svg {
            transform: rotate(45deg);
          }
        }
      }
      p {
        display: flex;
      }
    }
  }
}
