@import "../../../assets/style/variables.module.scss";

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;

  .right {
    display: flex;
    align-items: center;
    ul {
      display: flex;
      gap: 20px;
      li {
        a {
          color: $--text-color;
          font-size: 18px;
          font-weight: 600;
        }
      }
    }

    .auth {
      display: flex;
      gap: 20px;
      align-items: center;
      margin-right: 20px;

      a {
        font-size: 18px;
        color: $--text-color;
        font-weight: 600;
        &:nth-child(2) {
          background: $--main-blue;
          padding: 14px 37px;
          border-radius: 8px;
          box-shadow: 0px 0px 18px 1px rgba(39, 43, 135, 0.25);
          color: white;
        }
      }
    }
  }
}

@media only screen and (max-width: 940px) {
  .header {
    .logo {
      svg {
        width: 100px;
      }
    }
    .right {
      flex-direction: row-reverse;
      gap: 12px;
      .auth {
        margin-right: 0;
        gap: 12px;
        a {
          font-size: 12px;

          &:nth-child(2) {
            padding: 9.5px 20px;
          }
        }
      }

      ul {
        li {
          a {
            font-size: 12px;
          }
        }
      }
    }
  }
}
