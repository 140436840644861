@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");

@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy-Black.woff2") format("woff2"),
    url("../fonts/Gilroy-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy-Light.woff2") format("woff2"),
    url("../fonts/Gilroy-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy-Regular.woff2") format("woff2"),
    url("../fonts/Gilroy-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy-Medium.woff2") format("woff2"),
    url("../fonts/Gilroy-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy-Thin.woff2") format("woff2"),
    url("../fonts/Gilroy-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy-Bold.woff2") format("woff2"),
    url("../fonts/Gilroy-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy-Extrabold.woff2") format("woff2"),
    url("../fonts/Gilroy-Extrabold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy-Semibold.woff2") format("woff2"),
    url("../fonts/Gilroy-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

$--main-blue: #272b87;
$--secondary-orange: #d5897e;
$--secondary-blue: #3ca8ec;
$--text-color: #454545;

$--cormorant: "Cormorant Garamond", serif;

$--stroke-blue: #dfe6ff;

body,
input,
textarea,
select {
  font-family: "Gilroy", sans-serif;
}

button {
  background: transparent;
  cursor: pointer;
  font-family: "Gilroy", sans-serif;
}

input[type="date"],
input[type="time"] {
  background: transparent !important;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--lightestgrey);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
