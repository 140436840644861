@import "../../../assets/style/variables.module.scss";

.selection {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  h5 {
    color: #959a9f;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.04em;
  }

  .selectionWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    button {
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 14px;
      color: #686868;
      font-weight: 400;
      letter-spacing: 0.04em;
      text-align: left;

      .square {
        width: 17px;
        height: 17px;
        border: 1px solid $--stroke-blue;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .active {
      color: #454545;
      font-weight: 600;
      .square {
        background: $--main-blue;
      }
    }
  }
}
