@import '../../../assets/style/variables.module.scss';

.date {
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: relative;

  input {
    opacity: 0;
  }

  h6 {
    font-weight: 500;
    box-sizing: border-box;
  }

  input[type='date']::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }

  h5 {
    color: #959a9f;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.05em;
  }

  h6 {
    font-size: 15px;
    color: #959a9f;
    font-weight: 500;
    letter-spacing: 0.04em;
  }
  label {
    border: 1px solid #d9d9d9;
    height: 46px;
    padding-left: 18px;
    padding-right: 15px;
    box-sizing: border-box;
    border-radius: 8px;
    letter-spacing: 0.07em;
    transition: all 0.3s ease;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
