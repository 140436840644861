@import "../../../assets/style/variables.module.scss";

.infoPart {
  display: flex;
  flex-direction: column;
  margin-top: 150px;
  align-items: center;
  gap: 50px;
  width: 100%;
  h3 {
    font-size: 34px;
    font-weight: 600;
    color: $--main-blue;
  }

  .gridWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    .card {
      padding: 0 47px;
      box-sizing: border-box;
      border-right: 1px solid rgba(223, 230, 255, 0.7);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &:last-child {
        border-right: unset;
      }

      h4 {
        color: $--text-color;
        font-weight: 600;
        text-align: center;
        margin-bottom: 15px;
        margin-top: 30px;
        font-size: 18px;
        line-height: 127%;
      }

      p {
        color: #686868;
        text-align: center;
        font-size: 14px;
        line-height: 127%;
      }
      img {
        height: 135px;
      }
    }
  }

  .gridWrapper2 {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media only screen and (max-width: 940px) {
  .infoPart {
    flex-direction: column;
    margin-top: 75px;

    .gridWrapper {
      grid-template-columns: 1fr;
      gap: 40px;

      .card {
        border-right: unset;
      }
    }
  }
}
