@import "../../assets/style/variables.module.scss";

.home {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow-x: hidden;

  .svg1 {
    position: absolute;
    left: 0;
    z-index: 0;
    top: 1000px;
  }

  .svg2 {
    position: absolute;
    right: 0;
    z-index: 0;
    top: 2400px;
  }

  .svg3 {
    position: absolute;
    left: 0;
    z-index: 0;
    top: 4300px;
  }

  .svg4 {
    position: absolute;
    right: 0;
    z-index: 0;
    bottom: 0;
  }

  .wrapper {
    width: 100%;
    max-width: 1200px;
    position: relative;
    z-index: 1;
  }

  .langWrapper {
    position: fixed;
    bottom: 30px;
    right: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    z-index: 100;

    .countryFlag {
      width: 40px;
      height: 24px;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    .arrow {
      width: 15px;
      height: 15px;
      transform: rotate(-90deg);

      svg {
        width: 100%;
        height: 100%;
      }
    }

    select {
      position: absolute;
      opacity: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      top: 0;
      left: 0;
    }
  }
}

@media only screen and (max-width: 940px) {
  .home {
    overflow-x: hidden;

    .svg3 {
      top: unset;
      bottom: 3000px;
    }

    .wrapper {
      width: 90%;
    }
  }
}
