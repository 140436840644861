@import "../../assets/style/variables.module.scss";

.profileContainer {
  display: flex;
  box-sizing: border-box;
  background: #fefefe;

  .seperatorTitle {
    font-size: 36px;
    width: 400px;
    font-weight: 600;
    color: $--main-blue;
  }

  .profileWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 128px;
    box-sizing: border-box;
    max-height: 100vh;
    overflow: scroll;

    .top {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    .line {
      width: 1px;
      height: calc(100% + 45px);
      background: $--stroke-blue;
      top: -45px;
      position: relative;
    }

    form {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    .left,
    .right {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }
    .right {
      gap: 20px;

      .submit {
        font-size: 14px;
        color: white;
        background: linear-gradient(92.08deg, #272b87 -11.22%, #3990d9 145.9%);
        height: 47px;
        width: 400px;
        border-radius: 3px;
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .switchWrapper {
        width: 400px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        margin-top: 40px;
      }

      .switch {
        display: flex;
        position: relative;
        align-items: center;
        gap: 10px;
        width: 100%;

        .textWrapper {
          display: flex;
          flex-direction: column;
          margin-top: 40px;

          p {
            margin-top: 6px;
            font-size: 12px;
            line-height: 16px;
            color: rgb(123, 123, 123);
          }
        }

        h5 {
          font-size: 14px;
          color: $--text-color;
          font-weight: 500;
        }

        input {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
        }

        .circleWrapper {
          width: 50px;
          min-width: 50px;
          height: 30px;
          border: 1px solid $--stroke-blue;
          border-radius: 20px;
          padding: 2px;
          transition: all 0.5s ease;
          display: flex;

          .circle {
            width: 30px;
            height: 100%;
            border-radius: 50%;
            background: $--stroke-blue;
          }
        }
      }

      .active {
        .circleWrapper {
          border: 1px solid $--main-blue;
          justify-content: flex-end;

          .circle {
            background: $--main-blue;
          }
        }
      }
    }

    .left {
      gap: 20px;
      .submit {
        font-size: 14px;
        color: white;
        background: linear-gradient(92.08deg, #272b87 -11.22%, #3990d9 145.9%);
        height: 47px;
        width: 400px;
        border-radius: 3px;
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .photo {
        display: flex;
        align-items: center;
        gap: 35px;
        position: relative;

        input {
          position: absolute;
          width: 100%;
          height: 100%;
          opacity: 0;
        }

        img {
          height: 145px;
          width: 145px;
          border: 3px dashed $--main-blue;
          border-radius: 50%;
          object-fit: cover;
          object-position: center;
        }

        button {
          background: white;
          border-radius: 8px;
          color: $--text-color;
          display: flex;
          align-items: center;
          gap: 10px;
          border: 1px solid $--stroke-blue;
          padding: 10px 20px;

          svg {
            margin-top: -3px;
          }
        }
      }
    }
  }
}

.bottom {
  box-sizing: border-box;
  padding-bottom: 100px;
  form {
    margin-top: 45px;

    .inputWrapper {
      display: flex;
      gap: 0px;
      justify-content: space-between;

      .line {
        width: 1px;
        height: calc(100% + 45px);
        min-height: 800px;
        background: $--stroke-blue;
        top: -45px;
        position: relative;
      }

      .column {
        width: 50%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 0 0;
        box-sizing: border-box;
        align-items: center;
        padding-top: 50px;

        &:nth-child(3) {
          margin-top: 42px;
        }
      }

      .submit {
        font-size: 14px;
        color: white;
        background: linear-gradient(92.08deg, #272b87 -11.22%, #3990d9 145.9%);
        height: 47px;
        width: 400px;
        border-radius: 3px;
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      span {
        color: rgb(211, 75, 75);
      }
    }

    .buttonWrapper {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      flex-direction: column;
      gap: 25px;
      margin-top: 40px;
      width: 100%;
      padding: 0 120px;
      box-sizing: border-box;

      .login {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: $--main-blue;
        width: 400px;

        a {
          font-weight: 700;
          color: $--main-blue;
          text-decoration: underline;
        }
      }
    }
  }
}

.ldsRipple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  scale: 0.6;
}
.ldsRipple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: ldsRipple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.ldsRipple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes ldsRipple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

.activeSubmit {
  background: green !important;
}

@media only screen and (max-width: 940px) {
  .profileContainer {
    padding: 130px 0 50px 0;
    align-items: center;
    justify-content: center;

    .seperatorTitle {
      width: 100%;
      font-size: 32px;
    }

    .profileWrapper {
      flex-direction: column;
      width: 90%;
      padding: 0;
      height: auto;
      overflow: auto;
      max-height: unset;

      .top {
        flex-direction: column;
        gap: 30px;
      }

      .right {
        .switchWrapper {
          width: 100%;
        }
      }
      .left,
      .right {
        width: 100%;

        form {
          width: 100%;

          .submit {
            width: 100%;
          }

          label {
            width: 100%;

            select {
              width: 100% !important;
            }
          }
        }
      }
    }
  }

  .bottom {
    width: 100%;
    flex-direction: column;
    padding: 0;
    gap: 20px;
    padding-bottom: 0;

    .line {
      display: none !important;
    }

    form {
      display: flex;
      flex-direction: column !important;
      .buttonWrapper {
        padding: 0;

        button {
          width: 100%;
        }
      }
      .inputWrapper {
        flex-direction: column;
        gap: 20px;

        .column {
          width: 100%;
          padding: 0;
          padding-top: 0;

          label {
            width: 100% !important;

            input,
            textarea,
            select,
            .fileWrapper,
            .fileInput {
              width: 100% !important;

              .inputWrapper {
                width: 100% !important;

                .fileWrapper {
                  width: 100% !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
