@import "../../assets/style/variables.module.scss";

.dashboard {
  background: #fafafa;
  min-height: 100vh;
  display: flex;
  .content {
    width: 100%;
    height: 100vh;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    padding: 128px 45px 45px 45px;
    box-sizing: border-box;

    .dashboardMessageContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 73vw;
      border-radius: 13px;

      .dashboardTitle {
        width: 100%;
        background: $--main-blue;
        padding: 14px 0;
        text-align: center;
        color: white;
        border-radius: 12px 12px 0 0;
        font-weight: 400;
        font-size: 18px;
      }
    }
  }

  .row {
    width: 100%;
    display: flex;
    gap: 25px;
    margin-top: 25px;
  }
}

.agreementPopup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba($color: $--main-blue, $alpha: 0.6);
  z-index: 9999;

  .card {
    background: white;
    padding: 30px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
}

@media only screen and (max-width: 940px) {
  .dashboard {
    align-items: center;
    justify-content: center;
    .content {
      padding: 130px 20px 50px 20px;
      width: 100%;
      box-sizing: border-box;

      .dashboardMessageContainer {
        width: 100%;
      }

      .row {
        flex-direction: column;
      }
    }
  }
}
