@import "../../assets/style//variables.module.scss";

.doctorDetail {
  display: flex;
  width: 100%;

  .content {
    width: 100vw;
    padding: 158px 0px 0 40px;
    box-sizing: border-box;
    height: 100vh;
    overflow: scroll;
    display: flex;
    justify-content: space-between;
    gap: 60px;

    border-bottom: 80px solid white;

    &::-webkit-scrollbar {
      display: none;
    }

    .fixedButton {
      position: fixed;
      bottom: 0;
      right: 0;
      z-index: 30;
      width: calc(80vw + 1px);
      background: white;
      border: 1px solid $--stroke-blue;
      padding: 10px 50px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      button {
        background: linear-gradient(92.08deg, #272b87 -11.22%, #3990d9 145.9%);
        border-radius: 10px;
        height: 50px;
        color: white;
        letter-spacing: 0.04em;
        font-weight: 400;
        padding: 0 50px;
      }
    }

    .left {
      display: flex;
      flex-direction: column;
      min-height: 100vh;

      .moreWrapper {
        button {
          background: linear-gradient(
            92.08deg,
            #272b87 -11.22%,
            #3990d9 145.9%
          );
          border-radius: 10px;
          height: 50px;
          color: white;
          letter-spacing: 0.04em;
          font-weight: 400;
          padding: 0 50px;
        }
      }

      h1 {
        font-size: 24px;
        font-weight: 600;
        color: $--text-color;
        margin-bottom: 7px;
      }

      .subTitle {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 18px;
      }

      .starWrapper {
        display: flex;
        gap: 8px;
        margin-bottom: 30px;
      }

      .doctorImage {
        width: 270px;
        height: 300px;
        object-fit: cover;
        object-position: center;
        border-radius: 10px;
        border: 1px solid $--stroke-blue;
        margin-bottom: 40px;
      }

      .country {
        display: flex;
        gap: 19px;
        align-items: center;
        margin-bottom: 26px;

        h5 {
          font-size: 20px;
          font-weight: 500;
          color: $--text-color;
        }

        svg {
          width: 48px;
          height: 33px;
        }
      }

      .hospital {
        display: flex;
        gap: 19px;
        align-items: center;
        margin-bottom: 35px;
        svg {
          width: 49px;
          height: 49px;
        }
        h5 {
          font-size: 20px;
          font-weight: 500;
          color: $--text-color;
        }
      }

      .website {
        border: 1px solid $--stroke-blue;
        display: flex;
        align-items: center;
        padding: 12px 16px;
        margin-bottom: 20px;
        gap: 8px;
        border-radius: 5px;

        a {
          color: $--main-blue;
          font-weight: 600;
        }

        svg {
          width: 20px;
          height: 20px;
          path {
            fill: $--main-blue;
          }
        }
      }

      .operation {
        width: 100%;
        position: relative;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        margin-bottom: 20px;

        h6 {
          width: 90%;
          border: 1px solid $--stroke-blue;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
          font-size: 16px;
          font-weight: 600;
          color: $--text-color;
          text-align: center;
          line-height: 24px;

          .hovered {
            display: none;
          }
        }

        .hoveredText {
          position: relative;

          .hovered {
            display: flex;
            position: absolute;
            background: white;
            font-size: 14px;
            padding: 5px 15px;
            right: -100px;
            bottom: -20px;
            border: 1px solid white;
            background: $--main-blue;
            color: white;
          }
        }

        div {
          width: 50px;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          left: 0;
          border: 1px solid $--stroke-blue;
          border-radius: 50%;
          background: white;
          padding: 10px;
          box-sizing: border-box;

          img {
            width: 100%;
            max-height: 40px;
            object-fit: contain;
            object-position: center;
          }
        }
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      gap: 50px;
      width: 55%;

      .box {
        width: 100%;
        position: relative;

        .icon {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          border: 1px solid $--stroke-blue;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: -25px;
          left: -25px;
          background: white;
          padding: 7px;
          box-sizing: border-box;
          z-index: 1;

          svg {
            width: 100%;
            height: 100%;
          }
        }

        .locationWrapper {
          box-sizing: border-box;
          border: 1px solid $--stroke-blue;
          padding: 35px;
          border-radius: 10px;
          max-height: 420px;
          overflow: scroll;
          display: grid;
          grid-template-columns: 1fr 1fr;
          row-gap: 20px;
          column-gap: 25px;
          margin-bottom: 60px;

          .imageCard {
            width: 100%;
            img,
            video {
              width: 100%;
              border: 1px solid $--stroke-blue;
              border-radius: 5px;
            }
          }
        }

        .doctorWrapper {
          box-sizing: border-box;
          border: 1px solid $--stroke-blue;
          padding: 35px;
          border-radius: 10px;
          display: grid;
          grid-template-columns: 1fr 1fr;
          row-gap: 20px;
          column-gap: 25px;
          margin-bottom: 60px;

          .imageCard {
            width: 100%;
            cursor: pointer;

            .pdfLink {
              display: flex;
              align-items: center;
              gap: 3px;
              align-items: center;
              color: $--main-blue;
              padding: 5px 12px;
              box-sizing: border-box;
              border-radius: 5px;
              font-weight: 600;
              font-size: 18px;
              background: $--secondary-blue;
              justify-content: center;
              width: 100%;
              height: 300px;
              color: white;

              svg {
                width: 17px;
                height: 17px;

                path {
                  stroke: white;
                }
              }
            }

            img,
            video {
              width: 100%;
              border: 1px solid $--stroke-blue;
              border-radius: 5px;
              height: 300px;
              box-sizing: border-box;
              object-fit: cover;
              object-position: center;
            }
          }
        }

        .referenceWrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 35px;
          box-sizing: border-box;
          border: 1px solid $--stroke-blue;
          border-radius: 10px;
          max-height: 420px;
          overflow: scroll;
          position: relative;

          &::before {
            content: "";
            height: 100%;
            width: 1px;
            background: $--stroke-blue;
            position: absolute;
          }

          h2 {
            font-size: 20px;
            font-weight: 600;
            letter-spacing: 0.05em;
            color: $--text-color;
            margin-bottom: 25px;
            width: 100%;
          }

          .referenceCardWrapper {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 30px;
            width: 100%;

            .referenceCard {
              p {
                letter-spacing: 0.04em;
                color: #686868;
                margin-top: 10px;
                font-weight: 400;
                line-height: 138%;
              }
              .cardInfo {
                display: flex;
                align-items: center;
                gap: 10px;
                h5 {
                  font-size: 16px;
                  color: $--text-color;
                  letter-spacing: 0.05em;
                  font-weight: 600;
                }
                h6 {
                  letter-spacing: 0.05em;
                  color: #686868;
                  font-size: 14px;
                  margin-top: 2px;
                  font-weight: 500;
                }
                img {
                  width: 35px;
                  height: 35px;
                  border-radius: 50%;
                  border: 1px solid $--stroke-blue;
                }
              }
            }
          }
        }

        .resumeBox {
          padding: 35px;
          box-sizing: border-box;
          border: 1px solid $--stroke-blue;
          border-radius: 10px;
          letter-spacing: 0.04em;
          line-height: 138%;
          font-weight: 400;
          color: $--text-color;

          h2 {
            font-size: 20px;
            font-weight: 600;
            letter-spacing: 0.05em;
            color: $--text-color;
            margin-bottom: 25px;
            width: 100%;
          }

          ul {
            display: flex;
            flex-direction: column;
            gap: 30px;
            list-style-type: circle;
            padding-left: 20px;
            box-sizing: border-box;

            .awwardsWrapper {
              display: flex;
              flex-direction: column;
              gap: 5px;

              img {
                width: 150px;
                height: 150px;
                object-fit: cover;
                object-position: center;
                border: 1px solid $--secondary-blue;
                border-radius: 5px;
              }

              .awwardLink {
                display: flex;
                align-items: center;
                gap: 3px;
                align-items: center;
                color: $--main-blue;
                padding: 5px 12px;
                border-radius: 5px;
                font-weight: 600;
                font-size: 18px;
                background: $--secondary-blue;
                width: 75px;
                height: 75px;
                justify-content: center;

                p {
                  color: white;
                }

                svg {
                  width: 17px;
                  height: 17px;

                  path {
                    stroke: white;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 940px) {
  .doctorDetail {
    .content {
      padding: 0;
      padding-top: 160px;
      padding-left: 50px;
      padding-right: 50px;
      box-sizing: border-box;
      flex-direction: column;
      height: 100%;
      overflow: auto;
      width: 100%;
      gap: 50px;

      .fixedButton {
        width: 100%;
        justify-content: center;
      }

      .left {
        min-height: auto;
        align-items: center;
        width: 100%;

        .operation {
          .hoveredText {
            .hovered {
              left: 30px;
              right: unset;
            }
          }
        }

        button {
          width: 100%;
        }
        .doctorImage {
          width: 170px;
          height: 170px;
          margin-bottom: 20px;
        }

        .country,
        .hospital {
          margin-bottom: 0px;
          svg {
            width: 20px;
          }
          h5 {
            font-size: 14px;
          }
        }
        .hospital {
          margin-bottom: 20px;
        }
      }

      .right {
        width: 100%;
        .box {
          .locationWrapper {
            grid-template-columns: 1fr;
          }

          .doctorWrapper {
            grid-template-columns: 1fr;
          }
          .referenceWrapper {
            &::before {
              display: none;
            }
            .referenceCardWrapper {
              grid-template-columns: 1fr;
            }
          }
        }
      }
    }
  }
}
