@import "../../assets/style/variables.module.scss";

.search {
  display: flex;
  width: 100%;

  .content {
    padding: 128px 40px 0 40px;
    box-sizing: border-box;
    height: 100vh;
    overflow: scroll;
    width: 100vw;

    .doctorList {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 65px;
      margin-top: 50px;
    }

    .list {
      grid-template-columns: 1fr;
    }
  }
}

@media only screen and (max-width: 940px) {
  .search {
    align-items: center;
    justify-content: center;

    .content {
      padding: 130px 30px 50px 30px;
      width: 100%;
      box-sizing: border-box;

      .doctorList {
        display: flex;
        flex-direction: column;
        margin-top: 30px;
      }
    }
  }
}
