@import "../../assets/style/variables.module.scss";

.register {
  display: flex;
  width: 100vw;
  align-items: flex-start;
  justify-content: space-between;
  //   padding: 50px 45px 105px 45px;
  padding: 50px 45px 105px 0;
  box-sizing: border-box;
  background: #fcfcfc;
  position: relative;
  min-height: 100vh;

  .acceptAll {
    padding: 10px 17px;
    box-sizing: border-box;
    border: 1px solid $--main-blue;
    cursor: pointer;

    h6 {
      color: $--main-blue;
      font-weight: 400;
    }
  }

  .accept {
    background: $--main-blue;

    h6 {
      color: white;
    }
  }

  .logo {
    position: absolute;
    left: 45px;
    top: 50px;
    height: 56px;
  }

  .banner {
    width: 50vh;
    height: 85vh;
    border-radius: 30px;
    box-shadow: -20px 0px 79px 2px rgba(213, 137, 126, 0.26);
    object-fit: cover;
    object-position: center;
    left: 45px;
    position: sticky;
    top: 50px;
    margin-top: 80px;
  }
  .content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      form {
        margin-top: 45px;

        .inputWrapper {
          display: flex;
          flex-direction: column;
          gap: 20px;

          span {
            color: rgb(211, 75, 75);
          }
        }

        .buttonWrapper {
          display: flex;
          flex-direction: column;
          gap: 25px;
          margin-top: 40px;

          .login {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 4px;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: $--main-blue;
            width: 100%;

            a {
              font-weight: 700;
              color: $--main-blue;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 940px) {
  .register {
    flex-direction: column;
    align-items: flex-start;
    width: 100vw;
    align-items: center;
    padding: 0;
    position: relative;
    height: 100%;
    min-height: 100vh;

    .logo {
      height: 35px;
      top: 30px;
      left: 30px;
      z-index: 2;
    }

    .banner {
      height: 190px;
      border-radius: 0;
      width: 100vw;
      box-shadow: -20px 0px 79px 2px rgba(213, 137, 126, 0.26);
      position: absolute;
      left: 0;
      top: 0;
      margin-top: 0;
    }

    &::before {
      content: "";
      width: 100vw;
      height: 190px;
      max-height: 190px;
      background: linear-gradient(92.08deg, #272b87 -11.22%, #3990d9 145.9%);
      mix-blend-mode: hard-light;
      top: 0;
      left: 0;
      position: absolute;
      z-index: 1;
    }

    .content {
      align-items: flex-start;
      width: 90%;
      min-height: 80vh;
      box-sizing: border-box;
      z-index: 2;
      margin-top: 120px;

      .wrapper {
        width: 100%;

        form {
          width: 100%;
          margin-top: 60px;
          height: unset;
        }
      }
    }
  }
}
