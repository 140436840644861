@import '../../assets/style/variables.module.scss';

.comingsoon {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  background: rgb(229, 229, 229);

  h1 {
    font-size: 26px;
    font-weight: 300;
  }
}
