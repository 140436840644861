.notFound {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url('../../assets/img/404.png');
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;

  h2 {
    font-weight: 300;
    font-size: 26px;
    color: #272b87;
  }
  h1 {
    color: #d5897e;
    font-size: 120px;
    margin-top: 20px;
  }
  h3 {
    font-size: 26px;
    color: #272b87;
    margin-top: 25px;
    font-weight: 600;
  }

  a {
    margin-top: 25px;
    color: white;
    background: #272b87;
    padding: 18px 50px;
    border-radius: 8px;
    box-shadow: 0px 0px 18px 1px rgba(39, 43, 135, 0.25);
  }
}
