@import '../../assets/style/variables.module.scss';

.register {
  display: flex;
  width: 100vw;
  align-items: flex-start;
  justify-content: space-between;
  //   padding: 50px 45px 105px 45px;
  padding: 150px 45px 105px 0;
  box-sizing: border-box;
  background: #fcfcfc;
  position: relative;
  min-height: 100vh;

  .remember {
    margin-bottom: 20px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .checked {
      h5 {
        color: $--main-blue;
      }
    }

    label {
      display: flex;
      gap: 4px;
      align-items: center;

      input {
        opacity: 0;
        width: 0;
        height: 0;
      }

      h5 {
        font-size: 16px;
        letter-spacing: 0.075em;
        font-weight: 400;
      }
    }

    a {
      font-size: 12px;
      color: $--main-blue;
      font-weight: 300;
      letter-spacing: 0.075em;
    }
  }

  .logo {
    position: absolute;
    left: 45px;
    top: 50px;
    height: 56px;
  }

  .content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      form {
        margin-top: 45px;

        .inputWrapper {
          display: flex;
          gap: 20px;

          .column {
            width: 50%;
            display: flex;
            flex-direction: column;
            gap: 20px;
          }

          span {
            color: rgb(211, 75, 75);
          }
        }

        .buttonWrapper {
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;
          flex-direction: column;
          gap: 25px;
          margin-top: 40px;
          width: 100%;

          .login {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 4px;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: $--main-blue;
            width: 400px;

            a {
              font-weight: 700;
              color: $--main-blue;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 940px) {
  .register {
    flex-direction: column;
    align-items: flex-start;
    width: 100vw;
    align-items: center;
    padding: 0;
    position: relative;
    height: 100%;
    min-height: 100vh;

    .logo {
      height: 35px;
      top: 30px;
      left: 30px;
      z-index: 2;
    }

    .banner {
      height: 190px;
      border-radius: 0;
      width: 100vw;
      box-shadow: -20px 0px 79px 2px rgba(213, 137, 126, 0.26);
      position: absolute;
      left: 0;
      top: 0;
    }

    &::before {
      content: '';
      width: 100vw;
      height: 190px;
      max-height: 190px;
      background: linear-gradient(92.08deg, #272b87 -11.22%, #3990d9 145.9%);
      mix-blend-mode: hard-light;
      top: 0;
      left: 0;
      position: absolute;
      z-index: 1;
    }

    .content {
      align-items: flex-start;
      width: 90%;
      min-height: 80vh;
      box-sizing: border-box;
      z-index: 2;
      margin-top: 120px;

      .wrapper {
        width: 100%;

        form {
          width: 100%;
          margin-top: 60px;
          height: unset;

          .inputWrapper {
            flex-direction: column;

            .column {
              width: 100%;

              label {
                width: 100% !important;

                input,
                textarea,
                select {
                  width: 100% !important;
                }
              }
            }
          }
        }
      }
    }

    .doctorInfo {
      .wrapper {
        width: 100%;
        flex-direction: column;

        form {
          display: flex;
          flex-direction: column !important;

          .inputWrapper {
            flex-direction: column;

            .column {
              width: 100%;

              label {
                width: 100% !important;

                input,
                textarea,
                select,
                .fileWrapper,
                .fileInput {
                  width: 100% !important;

                  .inputWrapper {
                    width: 100% !important;

                    .fileWrapper {
                      width: 100% !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
