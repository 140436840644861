@import '../../assets/style/variables.module.scss';

.login {
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: space-between;
  padding: 50px 45px 105px 0;
  box-sizing: border-box;
  background: #fcfcfc;
  position: relative;

  .logo {
    position: absolute;
    left: 45px;
    top: 50px;
    height: 56px;
  }

  .banner {
    width: 590px;
    height: 100%;
    border-radius: 30px;
    box-shadow: -20px 0px 79px 2px rgba(213, 137, 126, 0.26);
    object-fit: cover;
    object-position: center;
  }
  .content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      form {
        margin-top: 45px;

        .inputWrapper {
          display: flex;
          flex-direction: column;
          gap: 20px;
          span {
            color: rgb(211, 75, 75);
          }
        }

        .buttonWrapper {
          display: flex;
          flex-direction: column;
          gap: 25px;
        }
      }
    }
  }
}

@media only screen and (max-width: 940px) {
  .login {
    flex-direction: column;
    align-items: flex-start;
    width: 100vw;
    align-items: center;
    padding: 0;
    position: relative;
    height: 100%;
    min-height: 100vh;

    .logo {
      height: 35px;
      top: 30px;
      left: 30px;
      z-index: 2;
    }

    .banner {
      height: 290px;
      border-radius: 0;
      width: 100vw;
      box-shadow: -20px 0px 79px 2px rgba(213, 137, 126, 0.26);
      position: absolute;
      left: 0;
      top: -100px;
      object-position: 0 0;
    }

    &::before {
      content: '';
      width: 100vw;
      height: 190px;
      max-height: 190px;
      background: linear-gradient(92.08deg, #272b87 -11.22%, #3990d9 145.9%);
      mix-blend-mode: hard-light;
      top: 0;
      left: 0;
      position: absolute;
      z-index: 1;
    }

    .content {
      height: 100%;
      align-items: flex-start;
      width: 90%;
      min-height: 70vh;
      box-sizing: border-box;
      z-index: 2;
      margin-top: 120px;

      .wrapper {
        width: 100%;

        form {
          width: 100%;
          margin-top: 60px;
        }
      }
    }
  }
}
