@import "../../assets/style/variables.module.scss";

.date {
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: relative;

  h6 {
    font-weight: 500;
    box-sizing: border-box;
  }

  .dateWrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    div {
      width: 100% !important;
      height: 100% !important;
      opacity: 0;
    }
  }

  // input[type='date']::-webkit-calendar-picker-indicator {
  //   background: transparent;
  //   bottom: 0;
  //   color: transparent;
  //   cursor: pointer;
  //   left: 0;
  //   position: absolute;
  //   right: 0;
  //   top: 0;
  //   width: 100%;
  //   height: 100%;
  // }

  input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }

  input {
    background: red;
    position: absolute;
    width: 100%;
    height: 50px;
    left: 0;
    opacity: 0;
  }

  h5 {
    color: #979797;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.05em;
  }
  label {
    border: 1px solid #d9d9d9;
    width: 400px;
    height: 46px;
    padding-left: 18px;
    box-sizing: border-box;
    border-radius: 8px;
    letter-spacing: 0.07em;
    transition: all 0.3s ease;
    font-weight: 500;
    display: flex;
    align-items: center;
  }
}

.input {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .fileWrapper {
    border: 1px solid #d9d9d9;
    width: 400px;
    height: 46px;
    padding-left: 18px;
    box-sizing: border-box;
    border-radius: 8px;
    letter-spacing: 0.07em;
    transition: all 0.3s ease;
    font-weight: 500;
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 10px;
    margin-bottom: 15px;

    input {
      width: 100%;
      height: 100%;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
    }

    h6 {
      color: #979797;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.05em;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        height: 20px;
      }
    }
  }

  &:focus-within {
    h5 {
      color: $--main-blue;
    }
    input,
    textarea {
      border: 1px solid $--main-blue;
      box-shadow: 0px 0px 18px 1px rgba(39, 43, 135, 0.25);
    }
  }

  h5 {
    color: #979797;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.05em;
  }

  input,
  select,
  textarea {
    border: 1px solid #d9d9d9;
    width: 400px;
    height: 46px;
    padding-left: 18px;
    box-sizing: border-box;
    border-radius: 8px;
    letter-spacing: 0.07em;
    transition: all 0.3s ease;
    font-weight: 500;

    &::placeholder {
      color: #a3a3a3;
      font-weight: 400;
    }
  }

  textarea {
    padding-top: 15px;
    min-height: 115px;
    resize: none;
  }
}

.remember {
  margin-top: 25px;
  margin-bottom: 50px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .checked {
    h5 {
      color: $--main-blue;
    }
  }

  label {
    display: flex;
    gap: 4px;
    align-items: center;

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    h5 {
      font-size: 12px;
      letter-spacing: 0.075em;
      font-weight: 400;
    }
  }

  a {
    font-size: 12px;
    color: $--main-blue;
    font-weight: 300;
    letter-spacing: 0.075em;
  }
}

.password {
  position: relative;

  button {
    position: absolute;
    right: 20px;
    bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 25px;
      height: 25px;
    }
  }
}

.addButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: $--main-blue;
  width: 100%;
  border: 1px solid $--main-blue;
  border-radius: 5px;
  height: 45px;
}

.rewardWrapper {
  border: 1px solid #d9d9d9;
  background: white;
  border-radius: 7px;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;

  .rewardCard {
    background: #fafafa;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 30px 10px 10px 10px;
    position: relative;

    .fileInfoWrapper {
      margin-top: 20px;
      h5 {
        font-size: 12px;
        color: black;
      }
    }

    h2 {
      font-size: 16px;
      font-weight: 600;
    }

    h3 {
      font-size: 14px;
      font-weight: 500;
      margin-top: 6px;
      overflow: hidden;
      max-width: 340px;
    }

    h4 {
      font-size: 12px;
      font-weight: 500;
      margin-top: 6px;
    }

    button {
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }
}

.profileImage {
  h5 {
    color: #979797;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.05em;
    margin-bottom: 10px;
  }

  .photoCircle {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 1px solid $--main-blue;
    border-style: dashed;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      object-fit: cover;
      object-position: center;
    }

    input {
      width: 100%;
      height: 100%;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
    }
  }
}

.fileInput {
  h5 {
    color: #979797;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.05em;
    margin-bottom: 10px;
  }

  .imageWrapper {
    display: flex;
    gap: 5px;
    margin-bottom: 10px;

    .pdfWrapper {
      display: flex;
      align-items: center;
      gap: 5px;
      border: 1px solid #d9d9d9;
      background: #fafafa;
      padding: 6px 12px;
      border-radius: 5px;
      color: #404040;
      svg {
        width: 17px;
        height: 17px;
      }
    }

    img,
    video {
      width: 50px;
      height: 50px;
      object-fit: cover;
      object-position: center;
      border: 1px solid $--main-blue;
      border-style: dashed;
    }
  }

  .fileWrapper {
    border: 1px solid #d9d9d9;
    width: 400px;
    height: 46px;
    padding-left: 18px;
    box-sizing: border-box;
    border-radius: 8px;
    letter-spacing: 0.07em;
    transition: all 0.3s ease;
    font-weight: 500;
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 10px;
    margin-bottom: 15px;

    input {
      width: 100%;
      height: 100%;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
    }

    h6 {
      color: #979797;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.05em;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        color: #979797 !important;
        overflow: hidden;
        max-width: 340px;
      }

      svg {
        height: 20px;
      }
    }
  }
}

.checkInput {
  display: flex;
  gap: 12px;
  align-items: center;

  .doc {
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
    flex-direction: column;
    background: rgba($color: #000000, $alpha: 0.5);
    z-index: 100;

    .bar {
      width: 70vw;
      background: $--main-blue;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 20px;
      box-sizing: border-box;

      h5 {
        color: white;
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 0.05em;
      }

      button {
        color: white;
      }
    }

    iframe {
      height: 90vh;
      width: 70vw;
    }
  }

  &:focus-within {
    h5 {
      color: $--main-blue;
    }
    input,
    textarea {
      border: 1px solid $--main-blue;
      box-shadow: 0px 0px 6px 1px rgba(39, 43, 135, 0.25);
    }
  }

  .title {
    color: #979797;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.05em;
    cursor: pointer;
    text-decoration: underline;
  }

  input {
    border: 1px solid #d9d9d9;
    width: 20px;
    min-width: 20px;
    height: 20px;
    box-sizing: border-box;
    border-radius: 3px;
    letter-spacing: 0.07em;
    transition: all 0.3s ease;
    font-weight: 500;

    &::placeholder {
      color: #a3a3a3;
      font-weight: 400;
    }
  }

  .checked {
    input {
      background: $--main-blue;
    }
  }
}

@media only screen and (max-width: 940px) {
  .date,
  .input,
  .password {
    input {
      width: 100% !important;
    }
  }

  .fileWrapper {
    width: 100% !important;
  }
}
