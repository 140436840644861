@import "../../assets/style/variables.module.scss";

.blog {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;

  .title {
    font-size: 42px;
    margin: 25px 0;
    width: 100%;
    text-align: center;
  }

  .wrapper {
    width: 100%;
    max-width: 1200px;

    .content {
      margin-top: 25px;
    }

    .cardWrapper {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 30px;
    }
  }
}

.card {
  .cover {
    width: 100%;
    border-radius: 20px 20px 0 0;
  }

  .bottomWrapper {
    background: white;
    border-radius: 20px;
    border: 1px solid #f3f3f3;
    margin-top: -30px;
    z-index: 1;
    position: relative;
    padding: 25px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .textWrapper {
      display: flex;
      flex-direction: column;

      h4 {
        font-size: 22px;
        color: $--text-color;
        font-weight: 600;
      }

      p {
        font-size: 16px;
        color: $--text-color;
        margin-top: 15px;
        line-height: 20px;
      }
    }

    .bottom {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      align-items: center;
      width: 100%;

      .date {
        font-size: 14px;
        color: #b8b8b8;
      }

      .personWrapper {
        display: flex;
        align-items: center;
        gap: 8px;

        h5 {
          font-size: 14px;
          font-weight: 400;
          color: $--text-color;
        }

        img {
          width: 30px;
          height: 30px;
          object-fit: cover;
          object-position: center;
          border-radius: 50%;
        }
      }
    }

    .readMore {
      background: linear-gradient(92.08deg, #272b87 -11.22%, #3990d9 145.9%);
      color: white;
      padding: 9px 16px 7.5px 16px;
      border-radius: 3px;
      margin-top: 20px;
    }
  }
}

.headCard {
  display: flex;
  margin-bottom: 50px;
  align-items: center;
  min-height: 450px;
  justify-content: flex-end;
  position: relative;
  margin-top: -40px;

  img {
    border-radius: 20px;
    height: 100%;
    width: calc(55% + 45px);
    object-fit: cover;
    object-position: center;
    position: relative;
    position: absolute;
    left: 0;
  }

  .info {
    border: 1px solid #f3f3f3;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 45px;
    box-sizing: border-box;
    border-radius: 20px;
    background: white;
    width: 45%;
    position: relative;
    h2 {
      font-size: 38px;
      line-height: 42px;
      margin-bottom: 15px;
      color: $--main-blue;
    }

    p {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 25px;
      color: $--text-color;
    }

    .bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .person {
        display: flex;
        flex-direction: column;
        gap: 5px;

        h5 {
          font-size: 16px;
          color: $--text-color;
          font-weight: 600;
        }
        h6 {
          font-size: 14px;
          color: #b8b8b8;
          font-weight: 400;
        }
      }

      a {
        background: linear-gradient(92.08deg, #272b87 -11.22%, #3990d9 145.9%);
        color: white;
        padding: 9px 16px 7.5px 16px;
        border-radius: 3px;
      }
    }
  }
}

@media only screen and (max-width: 940px) {
  .blog {
    overflow-y: hidden;
    max-width: 100vw;
    .wrapper {
      width: 90%;

      .content {
        .cardWrapper {
          grid-template-columns: 1fr;
        }
      }
    }
  }

  .headCard {
    min-height: 200px;
    flex-direction: column;
    margin-top: 0;
    margin-bottom: 0px;

    img {
      position: static;
      width: 100%;
    }

    .info {
      width: 100%;
      padding: 25px;
      position: relative;
      top: -40px;
      h2 {
        font-size: 24px;
        line-height: 32px;
      }
    }
  }
}
