@import "../../../assets/style/variables.module.scss";

.bank {
  width: 60%;
  background-size: cover;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  box-sizing: border-box;
  position: relative;
  height: 470px;
  background: white;
  border: 1px solid $--stroke-blue;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }

  // &::after {
  //   content: '';
  //   width: 100%;
  //   height: 100%;
  //   background: #d5897e;
  //   mix-blend-mode: multiply;
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   border-radius: 10px;
  // }
  // &::before {
  //   content: '';
  //   width: 100%;
  //   height: 80%;
  //   background: linear-gradient(0deg, #c88177 0%, rgba(200, 129, 119, 0) 100%);
  //   mix-blend-mode: normal;
  //   position: absolute;
  //   bottom: 0;
  //   left: 0;
  //   border-radius: 10px;
  // }

  .logo {
    background: white;
    padding: 15px;
    box-sizing: border-box;
    border-radius: 5px;
    position: relative;
    z-index: 1;

    img {
      height: 32px;
    }
  }

  .content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
    z-index: 1;

    .info {
      h3 {
        color: white;
        font-size: 32px;
        line-height: 40px;
        letter-spacing: 0.04em;
        font-weight: 700;
        max-width: 400px;
      }
      p {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.04em;
        color: white;
        margin-top: 40px;
      }
    }

    button {
      color: #d5897e;
      font-size: 11px;
      letter-spacing: 0.04em;
      background: white;
      font-weight: 700;
      padding: 9px 16px;
      border-radius: 3px;
      position: absolute;
      right: 0;
    }
  }
}

@media only screen and (max-width: 940px) {
  .bank {
    width: 100%;
    padding: 15px;
    height: 250px;

    .logo {
      padding: 10px;
      margin-bottom: 20px;
      img {
        height: 20px;
      }
    }

    .content {
      .info {
        h3 {
          font-size: 18px;
          line-height: 26px;
        }

        p {
          margin-top: 10px;
          margin-bottom: 20px;
        }
      }

      button {
        font-size: 10px;
        padding: 5px 15px;
      }
    }
  }
}
