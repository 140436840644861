@import url("../../assets/style/variables.module.scss");

.forgotPassword {
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  padding: 50px 45px 105px 0;
  box-sizing: border-box;
  background: #fcfcfc;
  position: relative;

  .space {
    margin: 20px 0;
  }

  .logo {
    position: absolute;
    left: 45px;
    top: 50px;
    height: 56px;
  }

  .content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 400px;

    .emailIcon {
      height: 75px;
      margin-bottom: 30px;
    }

    .textInfo {
      font-size: 12px;
      font-weight: 500;
      color: #959a9f;
      margin-top: 20px;
      line-height: 15px;
      letter-spacing: 0.07em;

      span {
        color: #272b87;
      }
    }

    .buttonWrapper {
      margin-top: 70px;
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    h1 {
      font-size: 40px;
      color: #454545;
      margin-bottom: 40px;
    }

    form {
      display: flex;
      flex-direction: column;

      span {
        color: rgb(211, 75, 75);
        margin-top: 10px;
        display: block;
      }

      .infoText {
        font-size: 12px;
        font-weight: 500;
        color: #959a9f;
        margin-top: 20px;
        line-height: 15px;
        letter-spacing: 0.07em;
      }

      .buttonWrapper {
        margin-top: 70px;
        display: flex;
        flex-direction: column;
        gap: 30px;
      }
    }
  }
}

@media only screen and (max-width: 940px) {
  .forgotPassword {
    flex-direction: column;
    align-items: flex-start;
    width: 100vw;
    align-items: center;
    padding: 0;
    position: relative;
    height: 100%;
    min-height: 100vh;

    .logo {
      height: 35px;
      top: 30px;
      left: 30px;
      z-index: 2;
    }

    .content {
      height: 100%;
      align-items: flex-start;

      width: 90%;
      min-height: 70vh;
      box-sizing: border-box;
      z-index: 2;
      margin-top: 120px;

      .wrapper {
        width: 100%;

        form {
          width: 100%;
          margin-top: 60px;
        }
      }
    }
  }
}
