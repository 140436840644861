@import '../../../assets/style/variables.module.scss';

.questionWrapper {
  position: fixed;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(17px);
  width: 80vw;

  height: 100vh;
  top: 0;
  right: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;

  .questionCard {
    background: #fafafa;
    border-radius: 10px;
    width: 80%;
    height: calc(100% - 160px);
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    position: relative;

    .bottomBar {
      width: 100%;
      position: absolute;
      bottom: 0;
      height: 60px;
      background: #dfe6ff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 24px;
      box-sizing: border-box;

      .bottomInfo {
        display: flex;
        align-items: center;
        gap: 10px;
        .barWrapper {
          width: 400px;
          height: 8px;
          background: white;
          border-radius: 20px;
          overflow: hidden;
          border: 1px solid $--main-blue;

          .bar {
            box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
            border-radius: 20px;
            height: 100%;
            background: linear-gradient(
              92.08deg,
              #272b87 -11.22%,
              #3990d9 145.9%
            );
            transition: all 0.5s ease;
          }
        }
      }

      button {
        background: linear-gradient(92.08deg, #272b87 -11.22%, #3990d9 145.9%);
        color: white;
        padding: 9px 16px;
        border-radius: 3px;
      }
    }

    .questionSide {
      width: 60%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 64px;
      box-sizing: border-box;

      .currentQuestion {
        h2 {
          color: $--main-blue;
          font-size: 22px;
          font-weight: 600;
        }
        h3 {
          font-size: 22px;
          color: $--text-color;
          font-weight: 500;
          line-height: 27px;
          letter-spacing: 0.04em;
          margin-top: 20px;
        }

        .answerWrapper {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 30px;
          margin-top: 70px;

          .answer {
            display: flex;
            align-items: center;
            gap: 20px;

            span {
              text-align: left;
              color: #686868;
              font-size: 14px;
            }

            div {
              min-width: 30px;
              min-height: 30px;
              max-width: 30px;
              max-height: 30px;
              border: 1px solid $--stroke-blue;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
          .active {
            span {
              font-weight: 600;
            }
            div {
              background: $--main-blue;
            }
          }
        }
      }
    }

    .cardInfo {
      width: 40%;
      height: 100%;
      background: url('../../../assets/img/dashboard/doctor-background.png');
      background-size: cover;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      .infoInner {
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 1;
        gap: 33px;

        .right {
          background: white;
          width: 217px;
          height: 72px;
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 20px;
        }

        .doctor {
          display: flex;
          align-items: center;
          gap: 15px;

          img {
            width: 50px;
            height: 50px;
            border: 1px solid $--stroke-blue;
            border-radius: 50%;
          }

          h5 {
            font-size: 14px;
            color: white;
            font-weight: 600;
          }
        }
        .operation {
          display: flex;
          align-items: center;
          gap: 15px;

          .icon {
            width: 50px;
            height: 50px;
            border: 1px solid $--stroke-blue;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: white;

            svg {
              height: 30px;
              width: 30px;
            }
          }

          h5 {
            font-size: 14px;
            color: white;
            font-weight: 600;
          }
        }
        .flight {
          display: flex;
          align-items: center;
          gap: 15px;

          .icon {
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
              width: 30px;
              height: 30px;
              path {
                fill: white;
              }
            }
          }

          .info {
            display: flex;
            flex-direction: column;
            gap: 4px;
            h6 {
              font-size: 14px;
              font-weight: 700;
              color: white;
            }

            p {
              font-size: 14px;
              color: white;
              font-weight: 400;
            }
          }
        }
      }

      &::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba($color: #272b87, $alpha: 0.77);
      }
    }
  }
}

@media only screen and (max-width: 940px) {
  .questionWrapper {
    width: 100vw;
    background: transparent;
    backdrop-filter: unset;
    height: 85%;
    bottom: 0;
    top: unset;
    .questionCard {
      flex-direction: column;
      position: static;
      width: 100%;
      height: 100%;
      border-radius: 0;

      .cardInfo {
        width: 100%;
        position: relative;

        .infoInner {
          gap: 15px;
          height: 100%;
          padding-top: 30px;
          box-sizing: border-box;

          .doctor {
            img {
              height: 35px;
              width: 35px;
            }
          }

          .operation {
            .icon {
              width: 35px;
              height: 35px;
              padding: 10px;
              box-sizing: border-box;
            }
          }

          .flight {
            .icon {
              width: 35px;
              height: 35px;
            }
          }

          .right {
            position: absolute;
            bottom: -36px;
            left: 50%;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
            border: 1px solid $--main-blue;
          }
        }
      }

      .questionSide {
        width: 100%;
        padding: 30px;
        padding-bottom: 100px;
        padding-top: 70px;

        .currentQuestion {
          h2 {
            font-size: 18px;
          }
          h3 {
            font-size: 15px;
            line-height: 22px;
            margin-top: 15px;
          }

          .answerWrapper {
            margin-top: 30px;
            gap: 15px;
          }
        }
      }

      .bottomBar {
        left: 0;

        .bottomInfo {
          .barWrapper {
            width: 100px;
          }
        }
      }
    }
  }
}
