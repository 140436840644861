@import "../../assets/style/variables.module.scss";

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba($color: $--main-blue, $alpha: 0.5);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;

  .wrapper {
    width: 40vw;
    height: 30vh;
    background: white;
    border-radius: 10px;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    .bar {
      display: flex;
      background: rgb(224, 224, 224);
      padding: 25px 20px;
      box-sizing: border-box;
      justify-content: space-between;
      align-items: center;

      h5 {
        color: $--main-blue;
        font-weight: 600;
        font-size: 20px;
      }
    }

    .close {
      background: white;
      border: 1px solid $--stroke-blue;
      width: 30px;
      height: 30px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      color: #ff6767;
    }

    .content {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 20px;
      box-sizing: border-box;
      button {
        width: 400px;
        background: transparent;
        border: 1px solid $--main-blue;
        height: 60px;
        border-radius: 8px;
        font-size: 18px;
        font-weight: 600;
        color: $--main-blue;
        letter-spacing: 0.07em;
        display: flex;
        align-items: center;
        justify-content: center;

        &:first-child {
          background: #ff6767;
          border: 1px solid #fac3c3;
          color: white;
        }
      }
    }
  }
}

@media only screen and (max-width: 940px) {
  .popup {
    .wrapper {
      width: 90vw;

      .bar {
        h5 {
          font-size: 18px;
        }
      }

      .content {
        button {
          width: 90%;
        }
      }
    }
  }
}
