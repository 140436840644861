@import "../../../assets/style/variables.module.scss";

.wrapper {
  width: 100%;
  margin-top: 150px;

  .cardWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .card {
      width: 400px;
      background: white;
      padding: 6px 6px 30px 6px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 30px;
      border-radius: 8px;
      box-shadow: 0 4px 50px 5px rgba($color: #e1e1e1, $alpha: 0.5);
      border: 1px solid #ececec;

      .title {
        display: flex;
        flex-direction: column;
        align-items: center;
        h5 {
          font-size: 24px;
          color: $--text-color;
        }

        h6 {
          color: #a0a0a0;
          font-weight: 400;
          font-size: 18px;
          margin-top: 10px;
        }
      }

      img {
        width: 100%;
        border-radius: 5px;
      }
    }

    .textWrapper {
      width: 50%;
      position: relative;
      height: 710px;
      display: flex;
      align-items: center;

      .text {
        margin-bottom: 100px;
        h4 {
          font-size: 46px;
          line-height: 58px;
          font-weight: 600;
        }

        p {
          font-size: 18px;
          margin-top: 30px;
          line-height: 32px;
          max-width: 500px;
        }
      }

      .buttonWrapper {
        display: flex;
        gap: 30px;
        position: absolute;
        bottom: 0;

        button {
          height: 105px;
          padding: 5px;
          padding-right: 30px;
          background: white;
          border-radius: 8px;
          display: flex;
          align-items: center;
          gap: 25px;
          box-shadow: 0 4px 50px 5px rgba($color: #e1e1e1, $alpha: 0.5);
          border: 1px solid #ececec;

          p {
            text-align: left;
            font-size: 20px;
            color: $--text-color;
            font-weight: 600;
          }

          img {
            height: 100%;
            border-radius: 5px;
            width: 65px;

            object-fit: cover;
            object-position: center;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 940px) {
  .wrapper {
    margin-top: 75px;

    .cardWrapper {
      flex-direction: column;

      .card {
        width: 100%;

        img {
          height: 400px;
          object-fit: cover;
          object-position: center;
        }
      }

      .textWrapper {
        width: 100%;
        height: auto;
        flex-direction: column;

        .text {
          margin-bottom: 35px;
          margin-top: 35px;

          h4 {
            font-size: 32px;
            line-height: 42px;
          }

          p {
            font-size: 16px;
            line-height: 28px;
            margin-top: 10px;
          }
        }

        .buttonWrapper {
          flex-direction: column;
          position: static;
          width: 100%;
        }
      }
    }
  }
}
