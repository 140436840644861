@import "../../../assets/style/variables.module.scss";

.wrapper {
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .allButton {
    background: linear-gradient(92.08deg, #272b87 -11.22%, #3990d9 145.9%);
    color: white;
    padding: 12px 24px;
    border-radius: 3px;
    margin-top: 20px;
    font-size: 18px;
    margin: 20px auto;
  }

  .cardWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px;
  }
}

.card {
  .cover {
    width: 100%;
    border-radius: 20px 20px 0 0;
  }

  .bottomWrapper {
    background: white;
    border-radius: 20px;
    border: 1px solid #f3f3f3;
    margin-top: -30px;
    z-index: 1;
    position: relative;
    padding: 25px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .textWrapper {
      display: flex;
      flex-direction: column;

      h4 {
        font-size: 22px;
        color: $--text-color;
        font-weight: 600;
      }

      p {
        font-size: 16px;
        color: $--text-color;
        margin-top: 15px;
        line-height: 20px;
      }
    }

    .bottom {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      align-items: center;
      width: 100%;

      .date {
        font-size: 14px;
        color: #b8b8b8;
      }

      .personWrapper {
        display: flex;
        align-items: center;
        gap: 8px;

        h5 {
          font-size: 14px;
          font-weight: 400;
          color: $--text-color;
        }

        img {
          width: 30px;
          height: 30px;
          object-fit: cover;
          object-position: center;
          border-radius: 50%;
        }
      }
    }

    .readMore {
      background: linear-gradient(92.08deg, #272b87 -11.22%, #3990d9 145.9%);
      color: white;
      padding: 9px 16px 7.5px 16px;
      border-radius: 3px;
      margin-top: 20px;
    }
  }
}

.dashboard {
  margin-top: 0;
}

@media only screen and (max-width: 940px) {
  .wrapper {
    margin-top: 75px;
    .cardWrapper {
      grid-template-columns: 1fr;
    }
  }

  .dashboard {
    margin-top: 0;
  }
}
