@import "../../assets/style/variables.module.scss";

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  background: rgba($color: #000000, $alpha: 0.5);
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: scroll;
}

.content {
  background: white;
  padding: 30px;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .form {
    display: flex;
    gap: 20px;
  }

  .top {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 20px;

    .icon {
      svg {
        height: 35px;
        width: 35px;
        path {
          fill: $--main-blue;
        }
      }
    }
  }

  .left {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .right {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .error {
      color: red;
    }
    .main {
      width: 100%;
      height: 45px;
      background: $--main-blue;
      border-radius: 5px;
      color: white;
    }
  }
}

.dateInput {
  display: flex;
  flex-direction: column;

  h5 {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .inputWrapper {
    display: flex;
    border: 1px solid #d9d9d9;
    width: 340px;
    height: 45px;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    box-sizing: border-box;
    position: relative;
    border-radius: 5px;

    input {
      width: 100%;
      height: 100%;
    }
  }
}

.dropInput {
  display: flex;
  flex-direction: column;

  h5 {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .selectionWrapper {
    height: 150px;
    overflow-y: scroll;
    overflow-x: hidden;
    box-sizing: border-box;
    padding: 10px;
    border: 1px solid #d9d9d9;
    margin-top: -1px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    .square {
      width: 17px;
      height: 17px;
      border: 1px solid $--stroke-blue;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
    }

    .checked {
      background: $--main-blue;
      padding: 1px;
    }

    .card {
      width: 100%;
      display: flex;
      align-items: center;

      img {
        width: 30px;
        height: 30px;
        object-fit: cover;
        object-position: center;
        border-radius: 50%;
        margin-right: 5px;
      }

      .avatar {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        font-size: 10px;
        background: $--main-blue;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
      }

      h6 {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }

  .close {
    height: 0;
    padding: 0;
    border: unset;
  }

  .inputWrapper {
    display: flex;
    border: 1px solid #d9d9d9;
    width: 340px;
    height: 45px;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    box-sizing: border-box;
    position: relative;
    border-radius: 5px 5px 0 0;

    h6 {
      font-size: 14px;
      font-weight: 400;
    }

    svg {
      transform: rotate(180deg);
    }

    input {
      width: 100%;
      height: 100%;
    }
  }
}

.fileInput {
  display: flex;
  flex-direction: column;

  h5 {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .rewardWrapper {
    border: 1px solid #d9d9d9;
    background: white;
    border-radius: 7px;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .rewardCard {
      background: #fafafa;
      border: 1px solid #d9d9d9;
      border-radius: 5px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      padding: 30px 10px 10px 10px;
      position: relative;

      .fileInfoWrapper {
        margin-top: 20px;
        h5 {
          font-size: 12px;
          color: black;
        }
      }

      h2 {
        font-size: 16px;
        font-weight: 600;
      }

      h3 {
        font-size: 14px;
        font-weight: 500;
        margin-top: 6px;
      }

      h4 {
        font-size: 12px;
        font-weight: 500;
        margin-top: 6px;
      }

      button {
        position: absolute;
        right: 10px;
        top: 10px;
      }
    }
  }

  .inputWrapper {
    display: flex;
    border: 1px solid #d9d9d9;
    width: 340px;
    height: 45px;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    box-sizing: border-box;
    position: relative;
    border-radius: 5px;

    h6 {
      font-size: 14px;
      font-weight: 500;
    }

    input {
      width: 100%;
      height: 100%;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
    }
  }
}

.textInput {
  display: flex;
  flex-direction: column;

  h5 {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .inputWrapper {
    display: flex;
    border: 1px solid #d9d9d9;
    width: 340px;
    height: 45px;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    box-sizing: border-box;
    position: relative;
    border-radius: 5px;

    input,
    textarea {
      width: 100%;
      height: 100%;
      resize: none;
    }

    textarea {
      padding-top: 10px;
      box-sizing: border-box;
    }
  }

  .textArea {
    height: 150px;
  }
}

@media only screen and (max-width: 940px) {
  .popup {
    align-items: flex-start;
    padding-top: 30px;
    overflow-y: scroll;
    padding-bottom: 50px;

    .content {
      width: 90vw;
      margin-bottom: 50px;
      .inputWrapper {
        width: 100% !important;
      }
    }

    .form {
      flex-direction: column;
      width: 100%;

      .left,
      .right {
        label {
          .inputWrapper {
            width: 100% !important;
          }
        }
      }
    }
  }
}
