@import "../../../assets/style/variables.module.scss";

.titleWrapper {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 90px;

  h3 {
    font-size: 18px;
    color: #454545;
    font-weight: 500;
    letter-spacing: 0.5em;
  }

  h2 {
    font-size: 62px;
    color: $--main-blue;
    font-weight: 600;
    font-family: $--cormorant;
    margin-top: 15px;
  }
}

@media only screen and (max-width: 940px) {
  .titleWrapper {
    align-items: center;
    margin-bottom: 25px;
    h3 {
      font-size: 14px;
      text-align: center;
      letter-spacing: 0.3em;
    }

    h2 {
      font-size: 42px;
      text-align: center;
    }
  }
}
