@import "../../assets/style/variables.module.scss";

.wrapper {
  width: 100vw;
  height: 100vh;
  padding: 50px;
  box-sizing: border-box;

  .logoWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
      height: 40px;

      img {
        height: 100%;
      }
    }
  }

  .contentWrapper {
    width: 100%;
    height: 80vh;
    display: flex;
    align-items: center;
    transition: all 0.5s ease;

    .illustrationWrapper {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .illustration {
        scale: 0;
        height: 0;
        width: 0;
        transition: all 0.5s ease;
      }

      .activeIllustration {
        scale: 1;
        height: 70%;
        width: auto;
      }
    }

    .formWrapper {
      height: 100%;
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .backButton {
        display: flex;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 10px;
        width: 70%;

        svg {
          margin-bottom: 3px;
        }

        span {
          color: #a2a2a2;
          font-size: 16px;
        }
      }

      h3 {
        color: $--text-color;
        font-weight: 600;
        scale: 0;
        width: 0;
        height: 0;
        transition: all 0.5s ease;
        font-size: 0;

        span {
          font-size: 0;
          scale: 0;
        }
      }

      .activeTitle {
        width: 70%;
        scale: 1;
        margin-bottom: 60px;
        font-size: 40px;
        height: auto;
        line-height: 58px;

        span {
          font-family: $--cormorant;
          color: $--main-blue;
          font-weight: 700;
          font-size: 50px;
          scale: 1;
        }
      }

      .form {
        display: flex;
        flex-direction: column;
        width: 70%;

        .checkList {
          display: grid;
          grid-template-columns: 1fr;
          gap: 7px;
          margin-top: -20px;
          margin-bottom: -20px;

          .checkWrapper {
            display: flex;
            flex-direction: column;
            gap: 5px;

            span {
              font-size: 12px;
            }
          }
        }

        .acceptAll {
          padding: 10px 17px;
          box-sizing: border-box;
          border: 1px solid $--main-blue;
          cursor: pointer;

          h6 {
            color: $--main-blue;
            font-weight: 400;
          }
        }

        .accept {
          background: $--main-blue;

          h6 {
            color: white;
          }
        }

        span {
          color: rgb(211, 75, 75);
        }

        .date {
          display: flex;
          flex-direction: column;
          gap: 12px;
          position: relative;
          width: 100%;

          .iconWrapper {
            position: absolute;
            right: 5px;
            bottom: 5px;
          }

          h6 {
            font-weight: 500;
            font-size: 18px;
            box-sizing: border-box;
          }

          .dateWrapper {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;

            div {
              width: 100% !important;
              height: 100% !important;
              opacity: 0;
            }
          }

          input[type="date"]::-webkit-calendar-picker-indicator {
            background: transparent;
            bottom: 0;
            color: transparent;
            cursor: pointer;
            height: auto;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            width: auto;
          }

          input {
            background: red;
            position: absolute;
            width: 100%;
            height: 50px;
            left: 0;
            opacity: 0;
          }

          label {
            border-bottom: 1px solid #a3a3a3;
            padding-bottom: 10px;
            padding-left: 15px;
            box-sizing: border-box;
            letter-spacing: 0.07em;
            transition: all 0.3s ease;
            font-weight: 500;
            display: flex;
            align-items: center;
            color: $--main-blue;
          }
        }

        .inputWrapper {
          display: flex;
          flex-direction: column;
          gap: 40px;

          input,
          select {
            width: 100%;
            border-bottom: 1px solid #a3a3a3;
            font-size: 18px;
            color: $--main-blue;
            padding-bottom: 10px;
            padding-left: 15px;
            box-sizing: border-box;

            &::placeholder {
              color: $--text-color;
            }
          }

          .selectWrapper {
            position: relative;

            svg {
              position: absolute;
              right: 0;
              z-index: 0;
              bottom: 7px;
              pointer-events: none;
            }
          }

          .passwordWrapper {
            position: relative;

            button {
              position: absolute;
              right: 5px;
              bottom: 5px;
            }
          }
        }
      }

      .operationForm {
        display: flex;
        flex-direction: column;
        width: 70%;

        .input {
          border: 1px solid #d7d7d7;
          height: 45px;
          border-radius: 5px;
          overflow: hidden;
          position: relative;

          svg {
            position: absolute;
            z-index: 1;
            right: 10px;
            top: 5px;

            path {
              fill: #929292;
            }
          }

          input {
            width: 100%;
            height: 100%;
            padding-left: 15px;
            box-sizing: border-box;
            font-size: 18px;
          }
        }

        .cardWrapper {
          display: flex;
          flex-wrap: wrap;
          gap: 15px;
          margin-top: 20px;
          max-height: 250px;
          overflow-y: scroll;

          button {
            font-size: 22px;
            color: #7a7a7a;
            border: 1px solid #d7d7d7;
            padding: 6px 20px;
            border-radius: 5px;
          }

          .moreButton {
            border: 1px solid $--main-blue;
            color: $--main-blue;
            font-weight: 600;
          }

          .activeButton {
            background: $--main-blue;
            color: white;
          }
        }
      }

      .doctorForm {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        box-sizing: border-box;

        .cardWrapper {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          position: relative;
          width: 100%;
          gap: 40px;
          max-height: 50vh;
          overflow-y: scroll;
          overflow-x: visible;
          padding: 0 14vw;
          box-sizing: border-box;
        }

        .buttonWrapper {
          width: 45%;
        }
      }

      .buttonWrapper {
        display: flex;
        flex-direction: column;
        gap: 25px;
        margin-top: 40px;
        align-items: center;
        width: 100%;

        button {
          width: 100% !important;
        }

        .login {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 4px;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: $--main-blue;
          width: 100%;

          a {
            font-weight: 700;
            color: $--main-blue;
            text-decoration: underline;
          }
        }
      }

      .doctorCard {
        display: flex;
        flex-direction: column;
        box-shadow: 0px 4px 71px rgba(0, 0, 0, 0.05);
        width: 100%;
        min-width: 300px;
        position: relative;

        .selectedBar {
          background: $--main-blue;
          display: flex;
          align-items: center;
          gap: 12px;
          padding: 7px 0;
          padding-left: 15px;
          box-sizing: border-box;
          position: absolute;
          z-index: 1;
          width: 99.5%;
          border-radius: 10px 10px 0 0;
          top: 190px;
          left: 1px;
          border: 1px solid white;

          span {
            font-size: 16px;
            color: white;
            font-weight: 500;
          }
        }

        img {
          height: 216px;
          width: 99.5%;
          border: 1px solid #dfe6ff;
          border-radius: 10px 10px 0px 0px;
          bottom: -1px;
          position: relative;
          object-fit: cover;
          object-position: center;
        }

        .info {
          background: #ffffff;
          border-radius: 0px 0px 20px 20px;
          border: 1px solid #dfe6ff;
          width: 100%;
          display: flex;
          padding: 25px 18px;
          box-sizing: border-box;
          gap: 15px;
          flex-direction: column;

          .name {
            display: flex;
            justify-content: space-between;
            align-items: center;
            h5 {
              font-size: 16px;
              font-weight: 700;
              color: $--text-color;
            }

            .country {
              display: flex;
              align-items: center;
              gap: 7px;
              font-size: 16px;

              svg {
                width: 20px;
                height: 14px;
              }
            }
          }

          .operationCard {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 8px;

            p {
              border: 1px solid #dfe6ff;
              position: relative;
              padding: 8px 12px;
              font-size: 12px;
              border-radius: 7px;
            }

            button {
              border: 1px solid $--main-blue;
              position: relative;
              padding: 6px 12px;
              font-size: 12px;
              border-radius: 7px;
              color: $--main-blue;
              font-weight: 600;
              box-sizing: border-box;
            }
          }

          .bottomWrapper {
            border-top: 1px solid $--stroke-blue;
            padding-top: 15px;
            box-sizing: border-box;
            display: flex;
            justify-content: flex-end;
            button {
              font-size: 14px;
              background: linear-gradient(
                92.08deg,
                #272b87 -11.22%,
                #3990d9 145.9%
              );
              color: white;
              padding: 9px 16px 7.5px 16px;
              border-radius: 3px;
            }
          }
        }
      }

      .selectedCar {
        box-shadow: 0px 5px 0px rgba($--main-blue, 1);
        border-radius: 20px;
      }
    }
  }
}

@media only screen and (max-width: 940px) {
  .wrapper {
    overflow-x: hidden;
    height: 100%;
    padding: 20px;
    padding-top: 110px;

    .contentWrapper {
      flex-direction: column !important;
      height: 100%;

      .illustrationWrapper {
        width: 100%;
        margin-top: 30px;
        margin-bottom: 20px;

        .illustration {
          height: 150px;
        }
      }

      .formWrapper {
        width: 100% !important;

        .backButton {
          position: static;
          width: 100%;
        }

        .activeTitle {
          width: 100%;

          font-size: 28px;
          margin-bottom: 10px;

          span {
            font-size: 32px;
          }
        }

        .form {
          width: 100%;
        }

        .operationForm {
          width: 100%;

          .cardWrapper {
            display: grid;
            grid-template-columns: 1fr;
            button {
              font-size: 16px;
              padding: 12px 0;
            }
          }
        }

        .doctorForm {
          .cardWrapper {
            grid-template-columns: 1fr;
            padding: 0;
            height: 100%;
            max-height: unset;
            overflow-y: hidden;
            overflow-x: hidden;
          }

          .buttonWrapper {
            width: 100%;
          }
        }
      }
    }
  }
}
