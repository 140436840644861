@import '../../assets/style/variables.module.scss';

.title {
  font-size: 40px;
  font-weight: 700;
  color: $--text-color;
}

@media only screen and (max-width: 940px) {
  .title {
    color: white;
  }
}
